import React, { Component } from 'react';

class BTInfo extends Component {
  
      render() {
        return (
            <p style={{
                fontFamily: 'Blinker',
                textAlign: 'justify',
                color: 'white',
                fontSize: '2vh',
                height: '10vh',
                marginTop: 0,
                marginBottom: 0
            }}>
                Your hidden daily bias is visualized based on the videos you liked. Liking a video from a liberal/conservative news outlet will count towards liberal/conservative bias. Disliking has the opposite effect. See for yourself how you're doing over time.
            </p>
        );
      }
}

export default BTInfo;