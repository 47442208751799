import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

import '../App.css';

import welcome4 from '../assets/welcome4.JPG';

import { useSwipeable } from 'react-swipeable';

const Welcome4Child = ({redirectnow}) => {

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            console.log("swiped left in welcome 4")
            redirectnow(true);
        },
        onSwipedRight: () => {
            console.log("swiped right in welcome 4")
            redirectnow(true);
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div>
            <div style={{
                display:'flex',
                alignItems: "center",
                justifyContent: "center",
                height: '100vh'
            }} {...handlers}>
                <img src={welcome4} width='100%' alt='welcome page 4'/>
            </div>
        </div>
    );

}


class Welcome4 extends Component {

    constructor(props){
        super(props);
        this.setRedirectValue = this.setRedirectValue.bind(this);
    }

    state = {
        redirect: false
    }
    
    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/welcome5' />
        }
    }

    setRedirectValue = (value) =>{
        this.setState({
            redirect: value
        })
    }

    render() {
      return (
        <div>
            {this.renderRedirect()}
            <Welcome4Child redirectnow={this.setRedirectValue}></Welcome4Child>
        </div>
      );
    }
    
}

export default Welcome4;