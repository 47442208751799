import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';

import Chart from 'react-apexcharts';
import axios from 'axios';
import firebase from 'firebase/app';


import giphy from '../assets/giphy.gif';

const config = {
    myurl: process.env.REACT_APP_RECSYS_API_URL
};

class BTListElementAvg extends Component {
    constructor(props) {
        super(props);

        this.state = {
      
            series: [{
                name: 'Liberal Bias',
                data: [this.props.liberal]
                }, {
                name: 'Conservative Bias',
                data: [this.props.conservative]
            },
            ],
            options: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    toolbar: {
                        show: false
                    },
                    offsetX: -10
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '100%'
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                colors: ["#008FFB", "#FA4443"],
                title: {
                    align: 'center',
                    text: "Your Average Bias In The Last 7 Days:",
                    margin: 0,
                    offsetY: 25,
                    offsetX: 10,
                    style: {
                        fontSize: '2.2vh',
                        fontWeight: 'bold',
                        fontFamily: 'Blinker',
                        color: '#fff'
                    },
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (val) {
                            return val + "%"
                        }
                    }
                },
                fill: {
                    opacity: 1
                
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontFamily: 'Blinker',
                    labels: {
                        colors: ['#fff']
                    },
                    offsetX: 10
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                }
            }
        }

        this._isMounted = false;
        this.axiosCancelSource = null;
        this.thisUser = null;
    }

    async componentDidMount() {
        this._isMounted = true;
        await firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in
               let uid = firebase.auth().currentUser.uid;
               if(this._isMounted){
                this.thisUser = uid;
               }
            } else {
              // No user is signed in.
              console.error('user is not signed in. something is weird.');
              return null;
            }
        }); // with ES6 there is no need for .bind(this) while using arrow functions
        console.log(this.thisUser)
        await new Promise(resolve => {setTimeout(resolve, 500);});
        console.log(this.thisUser)

        this.axiosCancelSource = axios.CancelToken.source()

        const apiUrl = config["myurl"]+('api/biastracker');
        axios.get(apiUrl, {
            params: {
                userid: this.thisUser
            }
        })
        .then(
            (res) => {
                const mydata = res.data['biastracker']
                this._isMounted && this.setState({
                    data: mydata,
                    isLoaded: true
                });
                // console.log(this.state.data)

                this._isMounted && this.setState({
                    series: [{
                        name: 'Liberal Bias',
                        data: [mydata.avg.liberal]
                        }, {
                        name: 'Conservative Bias',
                        data: [mydata.avg.conservative]
                    }]
                })

                // Optional: write stats into firebase or cache it for Offline First Mode
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    componentWillUnmount(){
        if(this.axiosCancelSource){
            this.axiosCancelSource.cancel('Axios request canceled.')
        }
        this._isMounted = false;
    }

    render() {
        return (
            <div>
                <div id="chart">
                    {this.state.isLoaded ? <Chart options={this.state.options} series={this.state.series} type="bar" height='110' /> : 
                    <Row style={{justifyContent: "center", alignItems: "center" }}>
                        <img src={giphy} alt="Loading" style={{height: "10vh"}}/>
                    </Row>}
                </div>           
            </div>
        )
    }
}

export default BTListElementAvg;