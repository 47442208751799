import React, { Component } from 'react';
import "../../node_modules/video-react/dist/video-react.css";
import { Row, Col } from 'react-flexbox-grid';
import DeviceOrientation, {Orientation} from 'react-screen-orientation';

import '../App.css';

import medialab from '../assets/medialab.jpg';
import viral from '../assets/viral.png';
import rotategif from '../assets/rotatephone.gif';
import profileicon from '../assets/profile icon.jpg';
import VideoLoader from '../components/VideoLoader';
import { Redirect } from 'react-router-dom';

class Enlightened_v3 extends Component {

    constructor(props){
      super(props);
      let ush = (100-100*((132+window.outerWidth*9/16)/(window.outerHeight)))/2;
      this.uppersectionheight = ush + 'vh';
      this.setRedirectValue = this.setRedirectValue.bind(this);
      this.setRedirect = this.setRedirect.bind(this);
    }

    state = {
        redirect: false
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/profile' />
        }
    }

    setRedirectValue = (value) =>{
        this.setState({
            redirect: value
        })
    }

    render() {
      return (
          <DeviceOrientation lockOrientation={'landscape-primary'}> 
            <Orientation orientation='landscape' alwaysRender={false}>
                <div style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: 'black',
                    width: '100%',
                    height: '100vh',
                    overflow: 'hidden',
                    flexDirection: 'row'
                    }}>
                        <VideoLoader></VideoLoader>
                </div>
            </Orientation>
            <Orientation orientation='portrait' alwaysRender={false} onOrientationchange>
                {this.renderRedirect()}
                <div style={{
                    display: 'flex',
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "-7%",
                    marginTop: "2vh",
                    backgroundColor: 'black',
                    width: '100%',
                    height: '3vh',
                    flexDirection: 'row',
                    direction: 'rtl'
                }}>
                    <Col>
                        <Row>
                            <img src={profileicon} width = '10%' height = '10%' margin='auto' alt="profile" onClick={this.setRedirect}></img>
                        </Row>
                    </Col>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: 'black',
                    width: '100%',
                    height: '80vh'
                }}>
                    <Col>
                        <Row style={{justifyContent:"center", alignItems:"center"}}>
                            <p style={{color: 'white'}}>Rotate your device to watch news</p>
                        </Row>
                        <Row>
                            <img src={rotategif} width='100%' margin='auto' alt="rotatephone"></img>
                        </Row>
                    </Col>
                </div>
                <div style={{
                    display:'flex',
                    alignItems: "center",
                    justifyContent: "center",
                    height: '10vh',
                    marginBottom: '5vh',
                    flexDirection: 'row'
                }}>
                    <Col>
                        <img src={medialab} width='120px' margin='auto' alt="mit media lab"></img>
                    </Col>
                    <Col>
                        <img src={viral} width='120px' margin='auto' alt="viral"></img>
                    </Col>
                </div>
            </Orientation>
          </DeviceOrientation>
      );
    }
  }
  
  export default Enlightened_v3;
  