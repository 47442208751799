import React, { Component } from 'react';
import RunStreakElement from './RunStreakElement';
import axios from 'axios';
import firebase from 'firebase/app';

// https://apexcharts.com/docs/chart-types/heatmap-chart/

const config = {
    myurl: process.env.REACT_APP_RECSYS_API_URL
};

class RunStreak extends Component {
  
    constructor(props){
        super(props);
        this.state = {
            isLoaded: false,
            data: {
                days: 0,
                timespent: 0,
                videoswatched: 0,
                cliptime: 0
            },
            defaultdata:
            {
                days: 0,
                timespent: 0,
                videoswatched: 0,
                cliptime: 0                
            }
        }
        this._isMounted = false;
        this.axiosCancelSource = null;
        this.thisUser = null;
    }

    async componentDidMount() {
        this._isMounted = true;
        await firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in
               let uid = firebase.auth().currentUser.uid;
               if(this._isMounted){
                this.thisUser = uid;
               }
            } else {
              // No user is signed in.
              console.error('user is not signed in. something is weird.');
              return null;
            }
        }); // with ES6 there is no need for .bind(this) while using arrow functions
        console.log(this.thisUser)
        await new Promise(resolve => {setTimeout(resolve, 500);});
        console.log(this.thisUser)

        this.axiosCancelSource = axios.CancelToken.source()

        const apiUrl = config["myurl"]+('api/stats');
        axios.get(apiUrl, {
            params: {
                userid: this.thisUser
            }
        })
        .then(
            (res) => {
                const mydata = res.data['runstreak']
                this._isMounted && this.setState({
                    isLoaded: true,
                    data: mydata,
                });
                // Optional: write stats into firebase or cache it for Offline First Mode
            },
            (error) => {
                this._isMounted && this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    componentWillUnmount(){
        if(this.axiosCancelSource){
            this.axiosCancelSource.cancel('Axios request canceled.')
        }
        this._isMounted = false;
    }

    render() {
        return (
            <div>
                {this.state.isLoaded ?  <RunStreakElement data={this.state.data}></RunStreakElement> : <RunStreakElement data={this.state.defaultdata}></RunStreakElement>}
            </div>
        );
    }
}

export default RunStreak;