import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

// var global_user; // will contain user data (read only)


class Firebase {
    constructor() {
      firebase.initializeApp(config);
      this.auth = firebase.auth();
      this.db = firebase.database();

      this.auth.signInAnonymously().then((user)=>{
        //If the signInAnonymously method completes without error, the observer registered in the onAuthStateChanged will trigger and you can get the anonymous user's account data from the User object:
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // console.log("Hello World")

            // Pre-populate user's firebase database
            var uid = user.uid;
            let a = firebase.database().ref('Users');
                // a.update({'userID': uid});
                // global_user = console.log(a.child(uid).key)

            // Avoid overwrites of existing data
            //// videos watched
            a.child(uid).child('videoswatched').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("videoswatched exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("videoswatched didn't exist! initialize videoswatched!");
                a.child(uid).child('videoswatched').update({'total': 0});
                a.child(uid).child('videoswatched').update({'videolist': 0}); // Each element in list is video_id
              }
            });

            //// time spent
            a.child(uid).child('timespent').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("timespent exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("timespent didn't exist! initialize timespent!");
                a.child(uid).child('timespent').update({'total': 0}); // in s
                a.child(uid).child('timespent').update({'timeperday': 0}); // in {date: timespent in seconds}
              }
            })
              
            //// swipes
            a.child(uid).child('swipes').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("swipes exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("swipes didn't exist! initialize swipes!");
                a.child(uid).child('swipes').update({'total': 0});
                a.child(uid).child('swipes').update({'swipesperday': 0}); // in {date: swipecount}
              }
            })

            //// upswipe
            a.child(uid).child('upswipe').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("upswipe exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("upswipe didn't exist! initialize upswipe!");
                a.child(uid).child('upswipe').update({'total': 0});
                a.child(uid).child('upswipe').update({'upswipelist': 0}); // Each element in list is {date: {videoid: watchtime}}
              }
            })

            //// downswipe
            a.child(uid).child('downswipe').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("downswipe exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("downswipe didn't exist! initialize downswipe!");
                a.child(uid).child('downswipe').update({'total': 0});
                a.child(uid).child('downswipe').update({'downswipelist': 0}); // Each element in list is {date: {videoid: watchtime}}
              }
            })

            // //// enlighteningvideos
            // a.child(uid).child('enlighteningvideos').once("value", snapshot => {
            //   if(snapshot.exists()){
            //     // console.log("enlighteningvideos exists!");
            //     // const userData = snapshot.val();
            //   } else {
            //     // console.log("enlighteningvideos didn't exist! initialize enlighteningvideos!");
            //     a.child(uid).child('enlighteningvideos').update({'total': 0});
            //     a.child(uid).child('enlighteningvideos').update({'enlighteningvideoslist': 0}); // Each element in list is {date: {videoid: watchtime}}
            //   }
            // })

            //// sharedvideos
            a.child(uid).child('sharedvideos').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("sharedvideos exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("sharedvideos didn't exist! initialize sharedvideos!");
                a.child(uid).child('sharedvideos').update({'total': 0});
                a.child(uid).child('sharedvideos').update({'sharedvideoslist': 0}); // Each element in list is {videoid}}

                let miniobj = Object.assign({total: 0}, {list:0}, {});
                let platforms = [{'email': miniobj},{'facebook': miniobj},{'twitter': miniobj},{'whatsapp': miniobj},{'telegram': miniobj},{'reddit': miniobj},{'linkedin': miniobj}];
                let obj = Object.assign(...platforms, {}); //https://stackoverflow.com/questions/42974735/create-object-from-array

                a.child(uid).child('sharedvideos').update({'platform': obj}); // Each element in list is {platform_name: {total: ..., list: [videoid, ...]}}

              }
            })
          
            //// nolanchartenum
            a.child(uid).child('nolanchart').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("nolanchart exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("nolanchart didn't exist! initialize nolanchart!");
                a.child(uid).child('nolanchart').update({'nolan': 0}); // Enum encoding -  0: unknown, 1: liberal, 2: libertarian, 3: conservative, 4: authoritarian, 5: centrist
              }
            })

            //// videoplaylist
            a.child(uid).child('videoplaylist').once("value", snapshot => {
              if(snapshot.exists()){
                // console.log("videoplaylist exists!");
                // const userData = snapshot.val();
              } else {
                // console.log("videoplaylist didn't exist! initialize videoplaylist!");
                a.child(uid).child('videoplaylist').update({'recentplaylist': 0}); // Only one element in the format {playlistid: ..., listofvideourl: [videourl_1, videourl_2, ...]}
                a.child(uid).child('videoplaylist').update({'playlisthistory': 0}); // Only one element in the format {playlisthistoryid: ..., listofplaylistid: [playlistid_1, playlistid_2, ...]}
              }
            })

            //// surveyresponse (TBA)




            
            // ...
            // global_user = uid;
          } else {
            // User is signed out
            // ...
          }
          
        });

      }).catch(function(error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // ...
        console.error(error);
      });

      // firebase.auth().onAuthStateChanged((user) => {
      //   if (user) {
      //     // User is signed in, see docs for a list of available properties
      //     // https://firebase.google.com/docs/reference/js/firebase.User
      //     console.log('user is signed in')
      //     // var uid = user.uid;
      //     // let a = firebase.database().ref('Users');
      //     // a.update({"CurrentUser": uid});
      //     // ...
      //   } else {
      //     console.log('user is signed in')
      //     // User is signed out
      //     // ...
      //   }
      // });

    };

    // writeUserData(userId, name, email, imageUrl) {
    //   this.db.ref('users/' + userId).set({
    //     username: name,
    //     email: email,
    //     profile_picture : imageUrl
    //   });
    //   return;
    // }


    // var global_user; // will contain user data (read only)
    // var global_user_profile; // will contain additional user data (read only)

    // doSignInAnonymously = () => this.auth.signInAnonymously().catch(function(error) {
    //     // Handle Errors here.
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     // ...
    // });

    // doSignOut = () => this.auth.signOut();

}

// export {global_user as uid}; ==> not necessary because we have firebase.auth().currentUser.uid; this is the current user's uid
export default Firebase;