import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import firebase from 'firebase/app';

import '../App.css';

import welcome1 from '../assets/welcome1.JPG';

import { useSwipeable } from 'react-swipeable';

const Welcome1Child = ({redirectnow}) => {

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            redirectnow(true);
        },
        onSwipedRight: () => {
            redirectnow(true);
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <div>
            <div style={{
                display:'flex',
                alignItems: "center",
                justifyContent: "center",
                height: '100vh'
            }} {...handlers}>
                <img src={welcome1} width='100%' alt='welcome page 1'/>
            </div>
        </div>
    );

}


class Welcome1 extends Component {

    constructor(props){
        super(props);
        this.state = {
            redirecttomain: false,
            redirect: false
        }
        this.checkIfUserExists = this.checkIfUserExists.bind(this);
        this.setRedirectValue = this.setRedirectValue.bind(this);
        this.isFirstTimer = false; // for the first-time user
    }
    
    renderRedirect = () => {
        if (this.state.redirect) {
            if (this.state.redirecttomain === true){
                return <Redirect to='main' />
            } else {
                return <Redirect to='/welcome2' />
            }

        }
    }

    setRedirectValue = (value) =>{
        this.setState({
            redirect: value
        })
    }

    checkIfUserExists = async () => {
        await firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            // User is signed in
            let uid = firebase.auth().currentUser.uid;
            console.log(uid);            
            firebase.database().ref('/Users/' + uid +'/nolanchart/nolan').once('value').then((snapshot)=> {    
              if (snapshot.val()===0){
                console.log('anonymous user exists, but nolan does not exist yet')
                // User does not exist yet - show Nolan Screen first
              } else if (this.isFirstTimer) {
                // User is a first timer - show Nolan Screen first
                this.setState({redirecttomain: false});
              } else {
                // User exists - go straight to Enlightened Screen
                this.setState({redirecttomain: true});
              }
            }).catch((error) => {console.error(error)});
          } else {
            // No user is signed in or user is signed out
            console.log('user is not signed in. now let us create an anonymous user and sign in');
            firebase.auth().signInAnonymously().then(() => {
                console.log('New anonymous user signed in now');
                this.isFirstTimer = true;
                console.log(this.isFirstTimer);
            }).catch((error) => {console.error(error)});
            // this.setState({redirecttomain: false});
          }
        }); // with ES6 there is no need for .bind(this) while using arrow functions
    };

    componentDidMount(){
        this.checkIfUserExists();
    }

    render() {
      return (
        <div>
            {this.renderRedirect()}
            <Welcome1Child redirectnow={this.setRedirectValue}></Welcome1Child>
        </div>
      );
    }
    
}

export default Welcome1;


