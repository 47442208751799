import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class BTListElement extends Component {
    constructor(props) {
        super(props);

        this.state = {
      
            series: [{
                name: 'Liberal Bias',
                data: [this.props.liberal]
                }, {
                name: 'Conservative Bias',
                data: [this.props.conservative]
            },
            ],
            options: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    toolbar: {
                        show: false
                    },
                    offsetX: -10
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '100%',
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                colors: ["#008FFB", "#FA4443"],
                title: {
                    align: 'center',
                    text: this.props.date,
                    margin: 0,
                    offsetY: 25,
                    offsetX: 10,
                    style: {
                        fontSize: '2.2vh',
                        fontWeight: 'bold',
                        fontFamily: 'Blinker',
                        color: '#fff',
                    },
                },
                tooltip: {
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (val) {
                            return val + "%"
                        }
                    }
                },
                fill: {
                    opacity: 1
                
                },
                legend: {
                    show: false
                },
                xaxis: {
                    labels: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                noData: {
                    text: 'Fetching Data From Database ... For You :)'
                }
            }
        }
    }

    render() {
        return (
            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="bar" height='90' width='300'/>
            </div>
        )
    }
}

export default BTListElement;