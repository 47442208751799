import React, { Component } from 'react';
import LikesDistributionElement from './LikesDistributionElement';

class LikesDistribution extends Component {

    constructor(props){
        super(props);
        
        // https://www.journalism.org/2020/01/24/u-s-media-polarization-and-the-2020-election-a-nation-divided/

        this.state = {
            defaultdata: // Next version: Use Cache
            {
                likes: {
                    ABC: 0,   // purple    
                    BTV: 0,   // purple
                    CNN: 0,   // blue
                    FOX: 0,   // red
                    MSN: 0    // blue
                },
                dislikes: {
                    ABC: 0,   // purple    
                    BTV: 0,   // purple
                    CNN: 0,   // blue
                    FOX: 0,   // red
                    MSN: 0    // blue
                }             
            }
        }
    }

    render() {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: "center",
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginTop: '2vh',
                    backgroundColor: 'black',
                    height: '13vh',
                    flex: 1,
                    flexDirection: 'column'
                }}>
                    <p style={{
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '2vh',
                        height: '10vh',
                        marginTop: 0
                    }}>
                        Your aggregate likes and dislikes across five major TV news channels are visualized here. Since we're not telling you where the news is from while you're watching the videos, you might surprise yourself by your liking or disliking behavior.
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: 'black',
                    width: '100%',
                    height: '63vh',
                    marginTop: '2vh'
                }}>
                    <LikesDistributionElement data={this.state.defaultdata}></LikesDistributionElement>
                </div>
            </div>
        );
    }

}

export default LikesDistribution;