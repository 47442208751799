import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';

import Chart from 'react-apexcharts';
import axios from 'axios';
import firebase from 'firebase/app';

import giphy from '../assets/giphy.gif';

const config = {
    myurl: process.env.REACT_APP_RECSYS_API_URL
};

class LikesDistributionElement extends Component {
  
    constructor(props){
        super(props);

        this.state = {      
            series: [
                {
                    type: 'column',
                    name: 'MSNBC Likes',
                    data: [this.props.data.likes.MSN]
                },
                {
                    type: 'column',
                    name: 'MSNBC Dislikes',
                    data: [-this.props.data.dislikes.MSN]
                },
                {
                    type: 'column',
                    name: 'CNN Likes',
                    data: [this.props.data.likes.CNN]
                },
                {
                    type: 'column', 
                    name: 'CNN Dislikes',
                    data: [-this.props.data.dislikes.CNN]
                },
                {
                    type: 'column',
                    name: 'BTV Likes',
                    data: [this.props.data.likes.BTV]
                },
                {
                    type: 'column',
                    name: 'BTV Dislikes',
                    data: [-this.props.data.dislikes.BTV]
                },
                {
                    type: 'column',
                    name: 'ABC Likes',
                    data: [this.props.data.likes.ABC]
                },
                {
                    type: 'column',
                    name: 'ABC Dislikes',
                    data: [-this.props.data.dislikes.ABC]
                },
                {
                    type: 'column',
                    name: 'FOX Likes',
                    data: [this.props.data.likes.FOX]
                },
                {
                    type: 'column',
                    name: 'FOX Dislikes',
                    data: [-this.props.data.dislikes.FOX]
                }
            ],
            options: {
                chart: {
                    type: 'bar',
                    horizontal: false,
                    stacked: false,
                    stackType: '100%',
                    toolbar: {
                        show: false,
                        selection: false
                    },
                    selection: {
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        barHeight: '100%',
                        distributed: false,
                        columnWidth: '100%'
                    }
                },
                colors: ["#143253","#143253", "#008FFB","#008FFB",  "#800080","#800080", "#BF00FF","#BF00FF", "#FF2800","#FF2800"],
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    align: 'center',
                    text: 'Like/Dislike Distribution',
                    margin: 0,
                    offsetY: 25,
                    style: {
                        fontSize: '2.2vh',
                        fontWeight: 'bold',
                        fontFamily: 'Blinker',
                        color: '#fff',
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: false
                    }
                    // // shared: true,
                    // y: {
                    //     formatter: function (val) {
                    //         return val
                    //     }
                    // }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    show: false
                },
                xaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: ['#fff', '#fff', '#fff', '#fff', '#fff']
                        }
                    },
                    axisBorder: {
                        show: true
                    },
                    axisTicks: {
                        show: true
                    },
                    categories: ["MSNBC", "CNN", "BTV", "ABC", "FOX"],
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: ['#fff']
                        }
                    }
                },
                noData: {
                    text: 'Fetching Data From Database ... For You :)'
                },
                grid: {
                    show: true,
                    strokeDashArray: 1
                },
                dataLabels: {
                    enabled: true
                }
            },
            data: null
        }

        this._isMounted = false;
        this.axiosCancelSource = null;
        this.thisUser = null;
    }

    async componentDidMount() {
        this._isMounted = true;
        await firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in
               let uid = firebase.auth().currentUser.uid;
               if(this._isMounted){
                this.thisUser = uid;
               }
            } else {
              // No user is signed in.
              console.error('user is not signed in. something is weird.');
              return null;
            }
        }); // with ES6 there is no need for .bind(this) while using arrow functions
        console.log(this.thisUser)
        await new Promise(resolve => {setTimeout(resolve, 500);});
        console.log(this.thisUser)

        this.axiosCancelSource = axios.CancelToken.source()

        const apiUrl = config["myurl"]+('api/likedislike');
        axios.get(apiUrl, {
            params: {
                userid: this.thisUser
            }
        })
        .then(
            (res) => {
                const mydata = res.data['likedislike']
                this._isMounted && this.setState({
                    data: mydata,
                    isLoaded: true
                });
                // console.log(this.state.data)

                this._isMounted && this.setState({
                    series: [
                        {
                            type: 'column',
                            name: 'MSNBC Likes',
                            data: [mydata.likes.MSN]
                        },
                        {
                            type: 'column',
                            name: 'MSNBC Dislikes',
                            data: [-mydata.dislikes.MSN]
                        },
                        {
                            type: 'column',
                            name: 'CNN Likes',
                            data: [mydata.likes.CNN]
                        },
                        {
                            type: 'column', 
                            name: 'CNN Dislikes',
                            data: [-mydata.dislikes.CNN]
                        },
                        {
                            type: 'column',
                            name: 'BTV Likes',
                            data: [mydata.likes.BTV]
                        },
                        {
                            type: 'column',
                            name: 'BTV Dislikes',
                            data: [-mydata.dislikes.BTV]
                        },
                        {
                            type: 'column',
                            name: 'ABC Likes',
                            data: [mydata.likes.ABC]
                        },
                        {
                            type: 'column',
                            name: 'ABC Dislikes',
                            data: [-mydata.dislikes.ABC]
                        },
                        {
                            type: 'column',
                            name: 'FOX Likes',
                            data: [mydata.likes.FOX]
                        },
                        {
                            type: 'column',
                            name: 'FOX Dislikes',
                            data: [-mydata.dislikes.FOX]
                        }
                    ]
                })

                // Optional: write stats into firebase or cache it for Offline First Mode
            },
            (error) => {
                this._isMounted && this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    componentWillUnmount(){
        if(this.axiosCancelSource){
            this.axiosCancelSource.cancel('Axios request canceled.')
        }
        this._isMounted = false;
    }

    render() {
        return (
            <div>
                {/* Chart */}
                <div id="chart">
                    {this.state.isLoaded ? <Chart options={this.state.options} series={this.state.series} type="bar" height='300' width='300'/> : 
                    <Row style={{justifyContent: "center", alignItems: "center" }}>
                        <img src={giphy} alt="Loading" style={{height: "10vh"}}/>
                    </Row>
                    }
                </div>
            </div>
        );
    }
    
}

export default LikesDistributionElement;