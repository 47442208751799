import React, { Component } from 'react';

// https://apexcharts.com/docs/chart-types/heatmap-chart/

class RunStreakElement extends Component {

    render() {
        return (
            <div>
                {/* Logo */}
                <div style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: 'black',
                    width: '100%',
                    height: '78vh',
                    marginTop: '2vh',
                    flex: 1,
                    flexDirection: 'column'
                }}>
                    <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '3vh',
                        height: '5vh',
                        marginTop: 0
                        
                    }}>
                        Current Run Streak:
                    </p>
                    <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '5vh',
                        height: '8vh',
                        marginTop: 0
                        
                    }}>
                        {this.props.data.days} days
                    </p>
                    <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '3vh',
                        height: '5vh',
                        marginTop: 0
                        
                    }}>
                        Total Time On Latest News:
                    </p>
                    <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '5vh',
                        height: '8vh',
                        marginTop: 0
                        
                    }}>
                        {this.props.data.timespent} minutes
                    </p>
                    <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '3vh',
                        height: '5vh',
                        marginTop: 0
                        
                    }}>
                        News Clips Watched:
                    </p>
                    <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '5vh',
                        height: '8vh',
                        marginTop: 0
                        
                    }}>
                        {this.props.data.videoswatched} Clips
                    </p>
                                        <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '3vh',
                        height: '5vh',
                        marginTop: 0
                        
                    }}>
                        Average Time Per News Clip:
                    </p>
                    <p style={{ 
                        fontFamily: 'Blinker',
                        textAlign: 'justify',
                        color: 'white',
                        fontSize: '5vh',
                        height: '8vh',
                        marginTop: 0
                        
                    }}>
                        {this.props.data.cliptime} seconds
                    </p>
                </div>
            </div>
        );
    }
}

export default RunStreakElement;