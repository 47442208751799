import React, { useState, useRef } from 'react';
import firebase from 'firebase/app';
import MyVideoPlayerUpDown from './MyVideoPlayerUpDown';
import ShareButtonCollection from './ShareButtonCollection';
import { Row, Col } from 'react-flexbox-grid';
import yayclick from '../assets/yay click.gif';
import nopeclick from '../assets/nope click.gif';


const VideoSwipe2 = ({listofIDs, listofvideos}) => {

    const player = useRef();

    const [counter, setCount] = useState(0);
    const [videourl, setVideoURL] = useState(listofvideos[counter]);
    const [videoID, setVideoID] = useState(listofIDs[counter]);
    const [yayimg, setYay] = useState("/yay.png");
    const [nopeimg, setNope] = useState("/nope.png");
    const [videoFinished, setFinished] = useState(false);
    // const posterURI = "https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif";
  
    const swipeupaction = async (time) => {
      // console.log("Swiped Up. Like.")
      /////////////////////////////
      // Initialization of helper functions and variables
      // Firebase Reference Grab
      let userref = firebase.database().ref('Users');
      let uid = firebase.auth().currentUser.uid; // This is the current anonymous user's id!!!
  
      // date encoding as yyyymmdd, stored as today
      let d = new Date();
      let mm;
      if(d.getUTCMonth()<9){
        mm = '0'+String(d.getUTCMonth()+1);
      } else {
        mm = String(d.getUTCMonth()+1);
      }
      let today = String(d.getUTCFullYear())+mm+String(d.getUTCDate());
  
  
      
  
      /////////////////////////////
      // Update the swipes list
      //// increment total
  
      await firebase.database().ref('/Users/' + uid +'/swipes/total').once('value').then((snapshot)=> {
        userref.child(uid).child('swipes').update({'total': snapshot.val() + 1});
      }).catch((error) => {console.error(error)});
  
      // //// update swipesperday [in {date: swipecount}]
      await firebase.database().ref('/Users/' + uid +'/swipes/swipesperday').once('value').then((snapshot)=> {
        // if the value is 0 [meaning it's a new user], insert first {date: swipecount} element into the list
        if(snapshot.val()===0){
          let myObj={};
          myObj[today]=1;
          userref.child(uid).child('swipes').update({'swipesperday': myObj});
        } else {
        // if this is not a new user
          // check if the current date exists in the list
          if(snapshot.val().hasOwnProperty(today)){
            // if the current date exists, update the value
            firebase.database().ref('/Users/'+uid+'/swipes/swipesperday/'+today+'/').once('value').then((ss)=>{
              let myObj2={};
              myObj2[today]=ss.val()+1;
              userref.child(uid).child('swipes').child('swipesperday').update(myObj2);
            })
          } else {
            // if the current date doesn't exist, create currentdate and initialize it with 1
            let myObj3={};
            myObj3[today]=1;
            userref.child(uid).child('swipes').child('swipesperday').update(myObj3);
          }
        }
      }).catch((error) => {console.error(error)});
  
  
  
  
      /////////////////////////////
      // Update the upswipe list
      //// increment total
      await firebase.database().ref('/Users/' + uid +'/upswipe/total').once('value').then((snapshot)=> {
        userref.child(uid).child('upswipe').update({'total': snapshot.val() + 1});
      }).catch((error) => {console.error(error)});
  
      // //// add current videoid to upswipelist  Each element in list is {date: {videoid: watchtime}}
      await firebase.database().ref('/Users/' + uid + '/upswipe/upswipelist').once('value').then((snapshot)=>{
        // if the value is 0 [meaning it's a new user], insert first {date: {videoid: watchtime}} element into the list
          // console.log('user does not exist');
        if(snapshot.val()===0){
          let myObj={};
          let innerObj={};
          // console.log(time);
          innerObj[videoID] = time;
          // console.log(innerObj);
          if(time){
            myObj[today]=innerObj;
          } else {
            myObj[today]=0;
          }
          // console.log(myObj)
          userref.child(uid).child('upswipe').update({'upswipelist': myObj});
        } else {
        // if this is not a new user
          // console.log('user exists');
          // check if the current date exists in the list
          if(snapshot.val().hasOwnProperty(today)){
            // if the current date exists
              // console.log('current date exists');
  
              firebase.database().ref('/Users/' + uid + '/upswipe/upswipelist/'+today+'/').once('value').then((snapshot2)=>{
              // check if the current videoid exists
                if(snapshot2.val().hasOwnProperty(videoID)){
                  // current videoid exists ==> update the current video value
                  // console.log('current videoid exists');
  
                  firebase.database().ref('/Users/'+uid+'/upswipe/upswipelist/'+today+'/'+videoID+'/').once('value').then((snapshot3)=>{
                    let myObj3={};
                    myObj3[videoID]= parseFloat(snapshot3.val())+time;
                    userref.child(uid).child('upswipe').child('upswipelist').child(today).update(myObj3);
                  })
                } else {
                  // current videoid does not exist ==> create new entry
                  // console.log('current videoid does not exist');
                  let myObj2 ={};
                  if(time){
                    myObj2[videoID]=time;
                  } else {
                    myObj2[videoID]=0;
                  }
                  userref.child(uid).child('upswipe').child('upswipelist').child(today).update(myObj2);
                }
              }).catch((error) => console.error(error));
          } else {
            // if the current date doesn't exist, create currentdate and initialize it with {videoid: watchtime}
            // console.log('current date doesnt exist')
            let myObj4={};
            let myObj5={};
            if(time){
              myObj5[videoID]=time;
            } else {
              myObj5[videoID]=0;
            }
            myObj4[today]=myObj5;
            userref.child(uid).child('upswipe').child('upswipelist').update(myObj4);
          }
        }
      }).catch((error) => {console.error(error)});
  
  
  
      // /////////////////////////////
      // // Update videoswatched
      // //// increment total
      await firebase.database().ref('/Users/' + uid +'/videoswatched/total').once('value').then((snapshot)=> {
        userref.child(uid).child('videoswatched').update({'total': snapshot.val() + 1});
      }).catch((error) => {console.error(error)});
  
      //// add current videoid to videolist. Each element in list is {videoid: timespent}
      await firebase.database().ref('/Users/' + uid +'/videoswatched/videolist').once('value').then((snapshot)=> {
        // if the value is 0 [meaning it's a new user], insert first {videoid: timespent in seconds} element into the list
        if(snapshot.val()===0){
          let myObj={};
          if(time){
            myObj[videoID]=time;
          }
          else {
            myObj[videoID]=0;
          }
          userref.child(uid).child('videoswatched').update({'videolist': myObj});
        } else {
          // if this is not a new user
          // check if the current videoID exists in the list
          if(snapshot.val().hasOwnProperty(videoID)){
            // if the current videoID exists, update the value
            firebase.database().ref('/Users/'+uid+'/videoswatched/videolist/'+videoID+'/').once('value').then((ss)=>{
              let myObj2={};
              myObj2[videoID]=parseFloat(ss.val())+time;
              userref.child(uid).child('videoswatched').child('videolist').update(myObj2);
            })
          } else {
            // if the current videoID doesn't exist, create videoID and initialize it with timespent
            let myObj3={};
            if(time){
              myObj3[videoID]=time;
            } else {
              myObj3[videoID]=0;
            }
            userref.child(uid).child('videoswatched').child('videolist').update(myObj3);
          }
        }
      }).catch((error) => {console.error(error)});
  
      // /////////////////////////////
      // // Update timespent
      // //// increment total in hr:min:s
      await firebase.database().ref('/Users/' + uid +'/timespent/total').once('value').then((snapshot)=> {
        userref.child(uid).child('timespent').update({'total': time+parseFloat(snapshot.val())});
      }).catch((error) => {console.error(error)});
  
      //// add time spent on video to total time per day to list [in {date: timespent in seconds}]
      await firebase.database().ref('/Users/' + uid + '/timespent/timeperday').once('value').then((snapshot)=>{
        // if the value is 0 [meaning it's a new user], insert first {date: timespent in seconds} element into the list
        if(snapshot.val()===0){
          let myObj={};
          if(time){
            myObj[today]=time;
          } else {
            myObj[today]=0;
          }
          userref.child(uid).child('timespent').update({'timeperday': myObj});
        } else {
        // if this is not a new user
          // check if the current date exists in the list
          if(snapshot.val().hasOwnProperty(today)){
            // if the current date exists, update the value
            firebase.database().ref('/Users/'+uid+'/timespent/timeperday/'+today+'/').once('value').then((ss)=>{
              let myObj2={};
              myObj2[today]=parseFloat(ss.val())+time;
              userref.child(uid).child('timespent').child('timeperday').update(myObj2);
            })
          } else {
            // if the current date doesn't exist, create currentdate and initialize it with timespent
            let myObj3={};
            if(time){
              myObj3[today]=time;
            } else {
              myObj3[today]=0;
            }
            userref.child(uid).child('timespent').child('timeperday').update(myObj3);
          }
        }
      }).catch((error) => {console.error(error)});
  
  
      ///////////////////////////////////////////////////////////////////////////////////////
      // Select the next video to watch [NOTE: these variables don't update instantly! cf. React hooks under the hood: https://medium.com/the-guild/under-the-hood-of-reacts-hooks-system-eb59638c9dba]
  
      if (counter + 1 > listofIDs.length - 1){
        setCount(counter % 10 + 1);
        setYay(yayclick);
        setVideoURL(listofvideos[counter % 10 + 1]);
        setVideoID(listofIDs[counter % 10 + 1]);
        setTimeout(() => {
          setYay("/yay.png")
        }, 1000);
      } else {
        setCount(counter+1);
        setYay(yayclick);
        setVideoURL(listofvideos[counter+1]);
        setVideoID(listofIDs[counter+1]);
        setTimeout(() => {
          setYay("/yay.png")
        }, 1000);
      }

      // Player.src = listofvideos[counter]
      //setPosterURI("https://media.giphy.com/media/JoePLWxLD7cGc/giphy.gif")
      // setPosterURI("/assets/dislike.png")
  
      // console.log("after writing into firebase db")
    };
  
    const swipedownaction = async (time) => {
      // console.log("Swiped Down. Dislike")
        /////////////////////////////
      // Initialization of helper functions and variables
      // Firebase Reference Grab
      // console.log("before writing into firebase db")
      let userref = firebase.database().ref('Users');
      // console.log(uid)
      let uid = firebase.auth().currentUser.uid;
      // console.log(firebase.auth().currentUser.uid); // This is the current anonymous user's id!!!
  
      // date encoding as yyyymmdd, stored as today
      // console.log('date encoding now');
      let d = new Date();
      // encoding month into mm format
      let mm;
      if(d.getUTCMonth()<9){
        mm = '0'+String(d.getUTCMonth()+1);
      } else {
        mm = String(d.getUTCMonth()+1);
      }
      let today = String(d.getUTCFullYear())+mm+String(d.getUTCDate());
  
  
      
  
      /////////////////////////////
      // Update the swipes list
      //// increment total
  
      await firebase.database().ref('/Users/' + uid +'/swipes/total').once('value').then((snapshot)=> {
        userref.child(uid).child('swipes').update({'total': snapshot.val() + 1});
      }).catch((error) => {console.error(error)});
  
      //// update swipesperday [in {date: swipecount}]
      await firebase.database().ref('/Users/' + uid +'/swipes/swipesperday').once('value').then((snapshot)=> {
        // if the value is 0 [meaning it's a new user], insert first {date: swipecount} element into the list
        if(snapshot.val()===0){
          let myObj={};
          myObj[today]=1;
          userref.child(uid).child('swipes').update({'swipesperday': myObj});
        } else {
        // if this is not a new user
          // check if the current date exists in the list
          if(snapshot.val().hasOwnProperty(today)){
            // if the current date exists, update the value
            firebase.database().ref('/Users/'+uid+'/swipes/swipesperday/'+today+'/').once('value').then((ss)=>{
              let myObj2={};
              myObj2[today]=ss.val()+1;
              userref.child(uid).child('swipes').child('swipesperday').update(myObj2);
            })
          } else {
            // if the current date doesn't exist, create currentdate and initialize it with 1
            let myObj3={};
            myObj3[today]=1;
            userref.child(uid).child('swipes').child('swipesperday').update(myObj3);
          }
        }
      }).catch((error) => {console.error(error)});
  
  
  
  
      /////////////////////////////
      // Update the downswipe list
      //// increment total
      await firebase.database().ref('/Users/' + uid +'/downswipe/total').once('value').then((snapshot)=> {
        userref.child(uid).child('downswipe').update({'total': snapshot.val() + 1});
      }).catch((error) => {console.error(error)});
  
      //// add current videoid to downswipelist  Each element in list is {date: {videoid: watchtime}}
      await firebase.database().ref('/Users/' + uid + '/downswipe/downswipelist').once('value').then((snapshot)=>{
        // if the value is 0 [meaning it's a new user], insert first {date: {videoid: watchtime}} element into the list
          // console.log('user does not exist');
        if(snapshot.val()===0){
          let myObj={};
          let innerObj={};
          innerObj[videoID]= time;
          if(time){
            myObj[today]=innerObj;
          } else {
            myObj[today]=0;
          }
          userref.child(uid).child('downswipe').update({'downswipelist': myObj});
        } else {
        // if this is not a new user
          // console.log('user exists');
          // check if the current date exists in the list
          if(snapshot.val().hasOwnProperty(today)){
            // if the current date exists
              // console.log('current date exists');
  
              firebase.database().ref('/Users/' + uid + '/downswipe/downswipelist/'+today+'/').once('value').then((snapshot2)=>{
              // check if the current videoid exists
                if(snapshot2.val().hasOwnProperty(videoID)){
                  // current videoid exists ==> update the current video value
                  // console.log('current videoid exists');
  
                  firebase.database().ref('/Users/'+uid+'/downswipe/downswipelist/'+today+'/'+videoID+'/').once('value').then((snapshot3)=>{
                    let myObj3={};
                    myObj3[videoID]= parseFloat(snapshot3.val())+time;
                    userref.child(uid).child('downswipe').child('downswipelist').child(today).update(myObj3);
                  })
                } else {
                  // current videoid does not exist ==> create new entry
                  // console.log('current videoid does not exist');
                  let myObj2 ={};
                  if(time){
                    myObj2[videoID]=time;
                  } else {
                    myObj2[videoID]=0;
                  }
                  userref.child(uid).child('downswipe').child('downswipelist').child(today).update(myObj2);
                }
              }).catch((error) => console.error(error));
          } else {
            // if the current date doesn't exist, create currentdate and initialize it with {videoid: watchtime}
            // console.log('current date doesnt exist')
            let myObj4={};
            let myObj5={};
            if(time){
              myObj5[videoID]=time;
            } else {
              myObj5[videoID]=0;
            }
            myObj4[today]=myObj5;
            userref.child(uid).child('downswipe').child('downswipelist').update(myObj4);
          }
        }
      }).catch((error) => {console.error(error)});
  
  
  
      /////////////////////////////
      // Update videoswatched
      //// increment total
      await firebase.database().ref('/Users/' + uid +'/videoswatched/total').once('value').then((snapshot)=> {
        userref.child(uid).child('videoswatched').update({'total': snapshot.val() + 1});
      }).catch((error) => {console.error(error)});
  
      //// add current videoid to videolist. Each element in list is {videoid: timespent}
      await firebase.database().ref('/Users/' + uid +'/videoswatched/videolist').once('value').then((snapshot)=> {
        // if the value is 0 [meaning it's a new user], insert first {videoid: timespent in seconds} element into the list
        if(snapshot.val()===0){
          let myObj={};
          if(time){
            myObj[videoID]=time;
          }
          else {
            myObj[videoID]=0;
          }
          userref.child(uid).child('videoswatched').update({'videolist': myObj});
        } else {
          // if this is not a new user
          // check if the current videoID exists in the list
          if(snapshot.val().hasOwnProperty(videoID)){
            // if the current videoID exists, update the value
            firebase.database().ref('/Users/'+uid+'/videoswatched/videolist/'+videoID+'/').once('value').then((ss)=>{
              let myObj2={};
              myObj2[videoID]=parseFloat(ss.val())+time;
              userref.child(uid).child('videoswatched').child('videolist').update(myObj2);
            })
          } else {
            // if the current videoID doesn't exist, create videoID and initialize it with timespent
            let myObj3={};
            if(time){
              myObj3[videoID]=time;
            } else {
              myObj3[videoID]=0;
            }
            userref.child(uid).child('videoswatched').child('videolist').update(myObj3);
          }
        }
      }).catch((error) => {console.error(error)});
  
      /////////////////////////////
      // Update timespent
      //// increment total in hr:min:s
      await firebase.database().ref('/Users/' + uid +'/timespent/total').once('value').then((snapshot)=> {
  
        //TODO: check this function (buggy)
        // console.log(snapshot.val());
  
        userref.child(uid).child('timespent').update({'total': time+parseFloat(snapshot.val())});
      }).catch((error) => {console.error(error)});
  
      //// add time spent on video to total time per day to list [in {date: timespent in seconds}]
      await firebase.database().ref('/Users/' + uid + '/timespent/timeperday').once('value').then((snapshot)=>{
        // if the value is 0 [meaning it's a new user], insert first {date: timespent in seconds} element into the list
        if(snapshot.val()===0){
          let myObj={};
          if(time){
            myObj[today]=time;
          } else {
            myObj[today]=0;
          }
          userref.child(uid).child('timespent').update({'timeperday': myObj});
        } else {
        // if this is not a new user
          // check if the current date exists in the list
          if(snapshot.val().hasOwnProperty(today)){
            // if the current date exists, update the value
            firebase.database().ref('/Users/'+uid+'/timespent/timeperday/'+today+'/').once('value').then((ss)=>{
              let myObj2={};
              myObj2[today]=parseFloat(ss.val())+time;
              userref.child(uid).child('timespent').child('timeperday').update(myObj2);
            })
          } else {
            // if the current date doesn't exist, create currentdate and initialize it with timespent
            let myObj3={};
            if(time){
              myObj3[today]=time;
            } else {
              myObj3[today]=0;
            }
            userref.child(uid).child('timespent').child('timeperday').update(myObj3);
          }
        }
      }).catch((error) => {console.error(error)});
  
  
      ///////////////////////////////////////////////////////////////////////////////////////
      // Select the next video to watch [NOTE: these variables don't update instantly! cf. React hooks under the hood: https://medium.com/the-guild/under-the-hood-of-reacts-hooks-system-eb59638c9dba]
      // Make a bigger leap when video is disliked

      // IF Needed: Calculate skip length based on videoID comparison
      // console.log("wolobologogogo");
      // console.log(listofIDs.length);

      if (counter + 10 > listofIDs.length - 1){
        setCount(counter%10+1);
        setNope(nopeclick);
        setVideoURL(listofvideos[counter%10+1]);
        setVideoID(listofIDs[counter%10+1]);
        setTimeout(() => {
          setNope("/nope.png")
        }, 1000);
      } else {
        setCount(counter+10);
        setNope(nopeclick);
        setVideoURL(listofvideos[counter+10]);
        setVideoID(listofIDs[counter+10]);
        setTimeout(() => {
          setNope("/nope.png")
        }, 1000);
      }

      // Player.src = listofvideos[counter]
      // setPosterURI("https://media.giphy.com/media/5GoVLqeAOo6PK/giphy.gif")
    };
  
    // const tapaction = (isPlaying) => {
    //   isPlaying = !isPlaying;
    // }

    const finishedPlaying = async (mybool) => {
      setFinished(mybool)
      return mybool
    }

    const handleupclick = async () => {
      let mytime = player.current.getCurrentTime();
      let maxduration = player.current.getDuration();

      if(videoFinished){
        setFinished(false);
        await swipeupaction(maxduration);
      } else {
        await swipeupaction(mytime);
      }
    }

    const handledownclick = async () => {
      let mytime = player.current.getCurrentTime();
      let maxduration = player.current.getDuration();

      if(videoFinished){
        setFinished(false);
        await swipedownaction(maxduration);
      } else {
        await swipedownaction(mytime);
      }
    }

    return(
      <div>
          <MyVideoPlayerUpDown
            url={videourl}
            light
            width={window.innerWidth}
            onUp={swipeupaction}
            onDown={swipedownaction}
            player={player}
            finishedPlaying={finishedPlaying}
            // onTap={tapaction}
          >
          </MyVideoPlayerUpDown>
          <div>
              <Row style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                alignContent: "stretch",
                width: '100%',
                height: '20vh'
                }}>
                <Col style={{
                  flex: 1,
                  alignItems: "center",
                  }}>
                  <img src={nopeimg} height="70" width="80" className="centerimage" onClick={ (e) => handledownclick(e) } alt="white unlit lightbulb"></img>
                </Col>
                <Col style={{flex: 1}}>
                    <ShareButtonCollection videourl={videourl} videoid={videoID}></ShareButtonCollection>
                </Col>
                <Col style={{flex: 1}}>
                  {/* <p style={{color: 'red'}}> Placeholder for Swiping Up Button </p> */}
                  <img src={yayimg} height="70" width="80" className="centerimage" onClick={ (e) => handleupclick(e) } alt="yellow lit lightbulb"></img>
                </Col>
              </Row>
          </div>
          {/* <div style={{
                display: 'flex',
                flex: 9,
                justifyContent: "center",
                alignItems: "center",
                alignContent: "stretch",
                backgroundColor: 'black',
                width: '100%',
                height: '100px'
          }}>
          </div> */}
          {/* <div style={{
                  backgroundColor: 'black',
                  flex: 1,
                  width: 'screen.width',
                  height: '100%'
                  // height: '100px'
          }}>
            <Row>
              <Col xs></Col>
              <Col xs>
                <Row>
                <img src="/nope.png" height="36" width="36" className="centerimage" onClick={swipeleftaction} alt="white unlit lightbulb"></img>
                <img src="/switch2.png" height="36" width="36" className="centerimage" onClick={swipeupaction}></img>
                <img src="/yay.png" height="36" width="36" className="centerimage" onClick={swiperightaction} alt="yellow lit lightbulb"></img>
                </Row>
              </Col>
              <Col xs></Col>
            </Row>
          </div> */}
          {/* <div style={{
                  display: 'flex',
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: 'black',
                  width: '100%',
                  height: '32px'
          }}>
          </div> */}
          {/* <ShareButtonCollection videourl={videourl} videoid={videoID}></ShareButtonCollection> */}
      </div>
    );
  };


  export default VideoSwipe2;
  