import React, { Component } from 'react';
import axios from 'axios';
import VideoSwipe2 from '../components/VideoSwipe2';

import firebase from 'firebase/app';
import VideoLoading from './VideoLoading';

const config = {
    myurl: process.env.REACT_APP_RECSYS_API_URL
};

class VideoLoader extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoaded: false,
            listofvideos: [],
            listofIDs: [],
            // listofvideos_test: ["https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615416698578.f137ab3b-58a0-4f3f-8fef-b28c0d759e6a_segment4_clean.mp4", "https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615418468551.ff1ae460-4b2c-4a51-822d-33cab687f1c7_segment9_clean.mp4", "https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615404021792.0f5b83d0-d33f-4935-85bf-de5e086c1194_segment0_clean.mp4", "https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615404061201.1e4f1a70-47e5-4214-89fe-58cfed79c6a9_segment2_clean.mp4", "https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615404082700.5a8b384d-4f1f-4c54-9e0c-7a2c856e3c38_segment1_clean.mp4","https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615414840335.fb989702-75e8-4068-96d2-147db0b262ac_segment4_clean.mp4", "https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615416073641.6b12b8a6-0a70-402f-9299-6f0792e1c019_segment5_clean.mp4", "https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615414974786.3da38c37-bfe2-47c7-972f-07a1b9387c49_segment7_clean.mp4"]
            // listofvideos_test: ["https://super-glue-do-not-delete.s3.us-east-1.amazonaws.com/video/1615448438682.71c205ab-d220-45ad-9c87-8030605941e5_segment3.mp4"]
        };
        this.thisUser = null;
        this._isMounted = false;
        this.axiosCancelSource = null;
    }

    async componentDidMount() {
        this._isMounted = true;
        await firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in
               let uid = firebase.auth().currentUser.uid;
               if(this._isMounted){
                this.thisUser = uid;
               }
            } else {
              // No user is signed in.
              console.error('user is not signed in. something is weird.');
              return null;
            }
        }); // with ES6 there is no need for .bind(this) while using arrow functions

        //Sleep for 2 seconds for the firebase auth promise to resolve (to get the uid)
        console.log(this.thisUser)
        await new Promise(resolve => {setTimeout(resolve, 2000);});
        console.log(this.thisUser)

        this.axiosCancelSource = axios.CancelToken.source()

        const apiUrl = config["myurl"]+('api/playlist');
        axios.get(apiUrl, {
            params: {
                userid: this.thisUser
            }
        })
        .then(
            (res) => {
                const cliplist = res.data['cliplist']
                const clipidlist = res.data['clipidlist']
                this._isMounted && this.setState({
                    isLoaded: true,
                    listofvideos: cliplist,
                    listofIDs: clipidlist
                });

                // Optional: write cliplist and clipidlist into firebase for Offline First Mode
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    componentWillUnmount(){
        if(this.axiosCancelSource){
            this.axiosCancelSource.cancel('Axios request canceled.')
        }
        this._isMounted = false;
    }

    render(){
        return(            
            <div>
                {/* <VideoLoading></VideoLoading> */}
                {this.state.isLoaded ? <VideoSwipe2 listofvideos={this.state.listofvideos} listofIDs={this.state.listofIDs}></VideoSwipe2> : <VideoLoading></VideoLoading>}
                {/* <VideoSwipe2 listofvideos={this.state.listofvideos_test} listofIDs={this.listofIDs}></VideoSwipe2> */}
            </div>
        )
    }

}

export default VideoLoader;
