import React, { Component } from 'react';
import "../../node_modules/video-react/dist/video-react.css";

import { Col } from 'react-flexbox-grid';
// import Center from 'react-center'; 
 
import donkey from '../assets/donkey.jpg';
import elephant from '../assets/elephant.jpg';
import medialab from '../assets/medialab.jpg';
import viral from '../assets/viral.png';

import {Redirect} from 'react-router-dom';
import firebase from 'firebase/app';

import '../App.css';
import { white } from 'color-name';

const q = {color: 'white', textAlign: 'center'};

class Nolan extends Component {

    constructor(props){
        super(props);
        this.checkIfUserExists = this.checkIfUserExists.bind(this);
        this.userExists = false;
        this.checkIfUserExists();
    }

    state = {
        redirect: false
    }
    
    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }
    
    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/main' />
        }
    }

    elephant = () => {
        this.userRegistration("elephant");
    }

    donkey = () => {
        this.userRegistration("donkey");
    }

    userRegistration = (animal) => {
        // onClick - register Nolan field of the user in firebase and redirect to main application page
        let userref = firebase.database().ref('Users');
        let uid = firebase.auth().currentUser.uid;

        firebase.database().ref('/Users/' + uid +'/nolanchart/').once('value').then((snapshot)=> {
            userref.child(uid).child('nolanchart').update({'nolan': animal});
        }).catch((error) => {console.error(error)});

        this.setRedirect();
        this.renderRedirect();
    }

    checkIfUserExists = () => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            // User is signed in
            let uid = firebase.auth().currentUser.uid;            
            firebase.database().ref('/Users/' + uid +'/nolanchart/nolan').once('value').then((snapshot)=> {    
              if(snapshot.val()===0){
                // User does not exist yet - show Nolan Screen first
              } else {
                // User exists - go straight to Enlightened Screen
                this.setRedirect();
                this.renderRedirect();
              }
            }).catch((error) => {console.error(error)});
    
          } else {
            // No user is signed in.
            console.error('user is not signed in. something is weird.');
          }
        }); // with ES6 there is no need for .bind(this) while using arrow functions
    };

    render() {
      return (
        <div>
            {this.renderRedirect()}
            <div style={{
                display:'flex',
                alignItems: "center",
                justifyContent: "center",
                height: '80vh'
            }}>
                <Col>
                    <h1 style={q}>Enlightened</h1>
                    <div style={{
                        color: white,
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <p style={q}>select your political inclination</p>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: 'black',
                        width: '100%'
                    }}>
                    <img src={elephant} width='120px' margin='auto' onClick={()=>this.elephant()} alt = "conservative"></img>
                    <img src={donkey} width='120px' margin='auto' onClick={()=>this.donkey()} alt = "liberal"></img>
                    </div>
                </Col>

            </div>
            <div style={{
                display:'flex',
                alignItems: "center",
                justifyContent: "center",
                height: '20vh'
            }}>
                <img src={medialab} width='120px' margin='auto' alt="mit media lab"></img>
                <img src={viral} width='120px' margin='auto' alt="viral"></img>
            </div>
        </div>
      );
    }
}

export default Nolan;


