import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';

import BTListElement from '../components/BTListElement';
import BTListElementAvg from './BTListElementAvg';

class BTList extends Component {

    constructor(props){
        super(props)
        this.dateformatchange = this.dateformatchange.bind(this)
    }

    dateformatchange = (inputdate) => {
        // let year = inputdate.substring(0,4)
        let month = inputdate.substring(4,6)
        let date = inputdate.substring(6, inputdate.length)
        // console.log(year)
        // console.log(month)
        // console.log(date)
        let output = null;

        switch(month) {
            case "01":
                output = "January " + date 
                return output
            case "02":
                output = "February " + date
                return output
            case "03":
                output = "March " + date 
                return output
            case "04":
                output = "April " + date
                return output
            case "05":
                output = "May " + date 
                return output
            case "06":
                output = "June " + date
                return output
            case "07":
                output = "July " + date 
                return output
            case "08":
                output = "August " + date
                return output
            case "09":
                output = "September " + date 
                return output
            case "10":
                output = "October " + date
                return output
            case "11":
                output = "November " + date 
                return output
            case "12":
                output = "December " + date
                return output
            default:
                // do nothing
        }
    }

    render() {
        return (
            <div>
                <BTListElementAvg conservative = {this.props.dummyobject.avg.conservative} liberal = {this.props.dummyobject.avg.liberal}></BTListElementAvg>
                {this.props.dummyobject.timeline !== 0 ?
                    // Timeline is not 0. User has watched at least one video.                 
                    this.props.dummyobject.timeline.map((arrayobj) => {
                        // Change arrayobj.date from yyyymmd into Day Month Year
                        console.log(arrayobj.date)
                        let outputdate = this.dateformatchange(arrayobj.date)

                        return <BTListElement key={arrayobj.date} date={outputdate} conservative={arrayobj.conservative} liberal={arrayobj.liberal}></BTListElement>
                        })
                    :
                    <Row style={{justifyContent: "center", alignItems: "center" }}>
                        <p style={{color: 'white', justifyContent: "center", alignItems: "center", textAlign: "center", height: "3vh", fontWeight: "bold", marginTop: "5vh"}}> Start watching videos to view your timeline. :) </p>
                    </Row>
                }
            </div>
        )
    }
}

export default BTList;