import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import firebase from 'firebase/app';

import '../App.css';
import BiasTracker from '../components/BiasTracker.js';
import LikesDistribution from '../components/LikesDistribution.js';
import RunStreak from '../components/RunStreak.js';

import donkey from '../assets/donkey.jpg';
import elephant from '../assets/elephant.jpg';
import returnicon from '../assets/returnicon.png'; 
import rotategif from '../assets/rotatephone.gif';

import { Row, Col } from 'react-flexbox-grid';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import DeviceOrientation, {Orientation} from 'react-screen-orientation';

// find more similar-length names for 3 tabs
class Profile extends Component {

    constructor(props){
        super(props);
        this.handleBiasTrackerClick = this.handleBiasTrackerClick.bind(this);
        this.handleLikesDistributionClick = this.handleLikesDistributionClick.bind(this);
        this.handleRunStreakClick = this.handleRunStreakClick.bind(this);
    }

    state = {
        donkey: false,
        buttonClicked: 1,
    }

    handleBiasTrackerClick = () => {
        this.setState({
            buttonClicked: 1
        })
    }

    handleLikesDistributionClick = () => {
        this.setState({
            buttonClicked: 2
        })
    }

    handleRunStreakClick = () => {
        this.setState({
            buttonClicked: 3
        })
    }
    
    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }
    
    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/main' />
        }
    }

    setRedirectValue = (value) =>{
        this.setState({
            redirect: value
        })
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                let uid = firebase.auth().currentUser.uid;
                console.log("UID: " + uid);
                firebase.database().ref('/Users/' + uid + '/nolanchart/nolan').once('value').then((snapshot)=>{
                if (snapshot.val() === "donkey") {
                    this.setState({
                        donkey: true,
                    });
                }
        }).catch((error) => {console.error(error)});
            } else {
                // No user is signed in.
                console.error('user is not signed in. something is weird.');
            }
        });
    }

    render() {
        const buttonClicked = this.state.buttonClicked;
        let screen;
        if(buttonClicked === 1) {
            screen = <BiasTracker/>;
        } else if (buttonClicked === 2) {
            screen = <LikesDistribution/>;
        } else {
            screen = <RunStreak/>;
        }

        return (
            <DeviceOrientation>
                <Orientation orientation='portrait' alwaysRender={false}>
                <div>
                    {this.renderRedirect()}
                    {/* Logo */}
                    <div style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: 'black',
                        width: '100%',
                        height: '10vh',
                        flexDirection: 'row',
                        direction: 'ltr'
                    }}>
                        <Col>
                            <Row style={{
                                marginLeft: "7%",
                                marginRight: "7%",
                                marginTop: '2vh',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                alignItems: 'center'
                                }}>
                                <img src={returnicon} width = '10%' height = '10%' margin='auto' alt="return" onClick={this.setRedirect}></img>
                                {this.state.donkey
                                    ? <img src={donkey} width='25%' margin='auto' alt="liberal"></img>
                                    : <img src={elephant} width='25%' margin='auto' alt="conservative"></img>
                                }
                            </Row>
                        </Col>
                    </div>

                    {/* Navigation Bar (with 3 tabs) */}
                    <div style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: 'black',
                        width: '100%',
                        height: '10vh',
                    }}>                    
                        <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                            <ToggleButton value={1} variant={this.state.donkey ? 'primary' : 'danger'} onClick={()=>this.handleBiasTrackerClick()}>Hidden Bias</ToggleButton>
                            <ToggleButton value={2} variant={this.state.donkey ? 'primary' : 'danger'} onClick={()=>this.handleLikesDistributionClick()}>Likes / Dislikes </ToggleButton>
                            <ToggleButton value={3} variant={this.state.donkey ? 'primary' : 'danger'} onClick={()=>this.handleRunStreakClick()}>Run Streak</ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    {/* Content */}
                    <div style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: 'black',
                        width: '100%',
                        height: '80vh'
                    }}>
                        {screen}
                    </div>
                </div>
                </Orientation>
                <Orientation orientation='landscape' alwaysRender={false}>
                    <div style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: 'black',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                        <Col style={{justifyContent: "center", alignItems: "center"}}>
                            <Row style={{justifyContent:"center", alignItems:"center", marginTop: "10vh", marginBottom: "10vh"}}>
                                <p style={{color: 'white'}}>Rotate your device to return to the Profile page</p>
                            </Row>
                            <Row style={{justifyContent: "center", alignItems: "center" }}>
                                <img src={rotategif} width='50%' margin='auto' alt="rotatephone"></img>
                            </Row>
                        </Col>
                    </div>
                </Orientation>
            </DeviceOrientation>
      );
    }
    
}

export default Profile;


