import React, { Component, useState } from 'react';
import "../../node_modules/video-react/dist/video-react.css";

import { Row, Col } from 'react-flexbox-grid';
// import { Grid, Row, Col } from 'react-flexbox-grid';
// import Center from 'react-center';
import MyVideoPlayer from '../components/MyVideoPlayer';
import ShareButtonCollection from '../components/ShareButtonCollection';

import firebase from 'firebase/app';

import '../App.css';


const VideoSwipe = ({listofIDs, listofvideos}) => {

  const [counter, setCount] = useState(0);
  const [videourl, setVideoURL] = useState(listofvideos[counter]);
  const [videoID, setVideoID] = useState(listofIDs[counter]);
  const posterURI = "https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif";
  // const [posterURI, setPosterURI] = useState("https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif");
  // const [posterURI, setPosterURI] = useState("https://media.giphy.com/media/yIIQTYwmm6WvC/giphy.gif"); //lightbulb
  //  const [posterURI, setPosterURI] = useState("https://media.giphy.com/media/3o7aCXpnE2y0YCCoJa/giphy.gif");
  // const [posterURI, setPosterURI] = useState("https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png")

  const swipeleftaction = (time) => {
    console.log("Swiped Left. Dislike.")

    /////////////////////////////
    // Initialization of helper functions and variables
    // Firebase Reference Grab
    // console.log("before writing into firebase db")
    let userref = firebase.database().ref('Users');
    // console.log(uid)
    let uid = firebase.auth().currentUser.uid;
    // console.log(firebase.auth().currentUser.uid); // This is the current anonymous user's id!!!

    // date encoding as yyyymmdd, stored as today
    // console.log('date encoding now');
    let d = new Date();
    // encoding month into mm format
    let mm;
    if(d.getUTCMonth()<9){
      mm = '0'+String(d.getUTCMonth()+1);
    } else {
      mm = String(d.getUTCMonth()+1);
    }
    let today = String(d.getUTCFullYear())+mm+String(d.getUTCDate());


    

    /////////////////////////////
    // Update the swipes list
    //// increment total

    firebase.database().ref('/Users/' + uid +'/swipes/total').once('value').then((snapshot)=> {
      userref.child(uid).child('swipes').update({'total': snapshot.val() + 1});
    }).catch((error) => {console.error(error)});

    //// update swipesperday [in {date: swipecount}]
    firebase.database().ref('/Users/' + uid +'/swipes/swipesperday').once('value').then((snapshot)=> {
      // if the value is 0 [meaning it's a new user], insert first {date: swipecount} element into the list
      if(snapshot.val()===0){
        let myObj={};
        myObj[today]=1;
        userref.child(uid).child('swipes').update({'swipesperday': myObj});
      } else {
      // if this is not a new user
        // check if the current date exists in the list
        if(snapshot.val().hasOwnProperty(today)){
          // if the current date exists, update the value
          firebase.database().ref('/Users/'+uid+'/swipes/swipesperday/'+today+'/').once('value').then((ss)=>{
            let myObj2={};
            myObj2[today]=ss.val()+1;
            userref.child(uid).child('swipes').child('swipesperday').update(myObj2);
          })
        } else {
          // if the current date doesn't exist, create currentdate and initialize it with 1
          let myObj3={};
          myObj3[today]=1;
          userref.child(uid).child('swipes').child('swipesperday').update(myObj3);
        }
      }
    }).catch((error) => {console.error(error)});




    /////////////////////////////
    // Update the leftswipe list
    //// increment total
    firebase.database().ref('/Users/' + uid +'/leftswipe/total').once('value').then((snapshot)=> {
      userref.child(uid).child('leftswipe').update({'total': snapshot.val() + 1});
    }).catch((error) => {console.error(error)});

    //// add current videoid to leftswipelist  Each element in list is {date: {videoid: watchtime}}
    firebase.database().ref('/Users/' + uid + '/leftswipe/leftswipelist').once('value').then((snapshot)=>{
      // if the value is 0 [meaning it's a new user], insert first {date: {videoid: watchtime}} element into the list
        // console.log('user does not exist');
      if(snapshot.val()===0){
        let myObj={};
        let innerObj={};
        innerObj[videoID]= time;
        if(time){
          myObj[today]=innerObj;
        } else {
          myObj[today]=0;
        }
        userref.child(uid).child('leftswipe').update({'leftswipelist': myObj});
      } else {
      // if this is not a new user
        // console.log('user exists');
        // check if the current date exists in the list
        if(snapshot.val().hasOwnProperty(today)){
          // if the current date exists
            // console.log('current date exists');

            firebase.database().ref('/Users/' + uid + '/leftswipe/leftswipelist/'+today+'/').once('value').then((snapshot2)=>{
            // check if the current videoid exists
              if(snapshot2.val().hasOwnProperty(videoID)){
                // current videoid exists ==> update the current video value
                // console.log('current videoid exists');

                firebase.database().ref('/Users/'+uid+'/leftswipe/leftswipelist/'+today+'/'+videoID+'/').once('value').then((snapshot3)=>{
                  let myObj3={};
                  myObj3[videoID]= parseFloat(snapshot3.val())+time;
                  userref.child(uid).child('leftswipe').child('leftswipelist').child(today).update(myObj3);
                })
              } else {
                // current videoid does not exist ==> create new entry
                // console.log('current videoid does not exist');
                let myObj2 ={};
                if(time){
                  myObj2[videoID]=time;
                } else {
                  myObj2[videoID]=0;
                }
                userref.child(uid).child('leftswipe').child('leftswipelist').child(today).update(myObj2);
              }
            }).catch((error) => console.error(error));
        } else {
          // if the current date doesn't exist, create currentdate and initialize it with {videoid: watchtime}
          // console.log('current date doesnt exist')
          let myObj4={};
          let myObj5={};
          if(time){
            myObj5[videoID]=time;
          } else {
            myObj5[videoID]=0;
          }
          myObj4[today]=myObj5;
          userref.child(uid).child('leftswipe').child('leftswipelist').update(myObj4);
        }
      }
    }).catch((error) => {console.error(error)});



    /////////////////////////////
    // Update videoswatched
    //// increment total
    firebase.database().ref('/Users/' + uid +'/videoswatched/total').once('value').then((snapshot)=> {
      userref.child(uid).child('videoswatched').update({'total': snapshot.val() + 1});
    }).catch((error) => {console.error(error)});

    //// add current videoid to videolist. Each element in list is {videoid: timespent}
    firebase.database().ref('/Users/' + uid +'/videoswatched/videolist').once('value').then((snapshot)=> {
      // if the value is 0 [meaning it's a new user], insert first {videoid: timespent in seconds} element into the list
      if(snapshot.val()===0){
        let myObj={};
        if(time){
          myObj[videoID]=time;
        }
        else {
          myObj[videoID]=0;
        }
        userref.child(uid).child('videoswatched').update({'videolist': myObj});
      } else {
        // if this is not a new user
        // check if the current videoID exists in the list
        if(snapshot.val().hasOwnProperty(videoID)){
          // if the current videoID exists, update the value
          firebase.database().ref('/Users/'+uid+'/videoswatched/videolist/'+videoID+'/').once('value').then((ss)=>{
            let myObj2={};
            myObj2[videoID]=parseFloat(ss.val())+time;
            userref.child(uid).child('videoswatched').child('videolist').update(myObj2);
          })
        } else {
          // if the current videoID doesn't exist, create videoID and initialize it with timespent
          let myObj3={};
          if(time){
            myObj3[videoID]=time;
          } else {
            myObj3[videoID]=0;
          }
          userref.child(uid).child('videoswatched').child('videolist').update(myObj3);
        }
      }
    }).catch((error) => {console.error(error)});

    /////////////////////////////
    // Update timespent
    //// increment total in hr:min:s
    firebase.database().ref('/Users/' + uid +'/timespent/total').once('value').then((snapshot)=> {
      userref.child(uid).child('timespent').update({'total': time+parseFloat(snapshot.val())});
    }).catch((error) => {console.error(error)});

    //// add time spent on video to total time per day to list [in {date: timespent in seconds}]
    firebase.database().ref('/Users/' + uid + '/timespent/timeperday').once('value').then((snapshot)=>{
      // if the value is 0 [meaning it's a new user], insert first {date: timespent in seconds} element into the list
      if(snapshot.val()===0){
        let myObj={};
        if(time){
          myObj[today]=time;
        } else {
          myObj[today]=0;
        }
        userref.child(uid).child('timespent').update({'timeperday': myObj});
      } else {
      // if this is not a new user
        // check if the current date exists in the list
        if(snapshot.val().hasOwnProperty(today)){
          // if the current date exists, update the value
          firebase.database().ref('/Users/'+uid+'/timespent/timeperday/'+today+'/').once('value').then((ss)=>{
            let myObj2={};
            myObj2[today]=parseFloat(ss.val())+time;
            userref.child(uid).child('timespent').child('timeperday').update(myObj2);
          })
        } else {
          // if the current date doesn't exist, create currentdate and initialize it with timespent
          let myObj3={};
          if(time){
            myObj3[today]=time;
          } else {
            myObj3[today]=0;
          }
          userref.child(uid).child('timespent').child('timeperday').update(myObj3);
        }
      }
    }).catch((error) => {console.error(error)});


    ///////////////////////////////////////////////////////////////////////////////////////
    // Select the next video to watch [NOTE: these variables don't update instantly! cf. React hooks under the hood: https://medium.com/the-guild/under-the-hood-of-reacts-hooks-system-eb59638c9dba]

    setCount(counter+1);
    setVideoURL(listofvideos[counter+1]);
    setVideoID(listofIDs[counter+1]);

    // Player.src = listofvideos[counter]
    //setPosterURI("https://media.giphy.com/media/JoePLWxLD7cGc/giphy.gif")
    // setPosterURI("/assets/dislike.png")

    // console.log("after writing into firebase db")
  };

  const swiperightaction = (time) => {
    console.log("Swiped Right. Continue Playing Video for a bit longer.")
      /////////////////////////////
    // Initialization of helper functions and variables
    // Firebase Reference Grab
    // console.log("before writing into firebase db")
    let userref = firebase.database().ref('Users');
    // console.log(uid)
    let uid = firebase.auth().currentUser.uid;
    // console.log(firebase.auth().currentUser.uid); // This is the current anonymous user's id!!!

    // date encoding as yyyymmdd, stored as today
    // console.log('date encoding now');
    let d = new Date();
    // encoding month into mm format
    let mm;
    if(d.getUTCMonth()<9){
      mm = '0'+String(d.getUTCMonth()+1);
    } else {
      mm = String(d.getUTCMonth()+1);
    }
    let today = String(d.getUTCFullYear())+mm+String(d.getUTCDate());


    

    /////////////////////////////
    // Update the swipes list
    //// increment total

    firebase.database().ref('/Users/' + uid +'/swipes/total').once('value').then((snapshot)=> {
      userref.child(uid).child('swipes').update({'total': snapshot.val() + 1});
    }).catch((error) => {console.error(error)});

    //// update swipesperday [in {date: swipecount}]
    firebase.database().ref('/Users/' + uid +'/swipes/swipesperday').once('value').then((snapshot)=> {
      // if the value is 0 [meaning it's a new user], insert first {date: swipecount} element into the list
      if(snapshot.val()===0){
        let myObj={};
        myObj[today]=1;
        userref.child(uid).child('swipes').update({'swipesperday': myObj});
      } else {
      // if this is not a new user
        // check if the current date exists in the list
        if(snapshot.val().hasOwnProperty(today)){
          // if the current date exists, update the value
          firebase.database().ref('/Users/'+uid+'/swipes/swipesperday/'+today+'/').once('value').then((ss)=>{
            let myObj2={};
            myObj2[today]=ss.val()+1;
            userref.child(uid).child('swipes').child('swipesperday').update(myObj2);
          })
        } else {
          // if the current date doesn't exist, create currentdate and initialize it with 1
          let myObj3={};
          myObj3[today]=1;
          userref.child(uid).child('swipes').child('swipesperday').update(myObj3);
        }
      }
    }).catch((error) => {console.error(error)});




    /////////////////////////////
    // Update the rightswipe list
    //// increment total
    firebase.database().ref('/Users/' + uid +'/rightswipe/total').once('value').then((snapshot)=> {
      userref.child(uid).child('rightswipe').update({'total': snapshot.val() + 1});
    }).catch((error) => {console.error(error)});

    //// add current videoid to rightswipelist  Each element in list is {date: {videoid: watchtime}}
    firebase.database().ref('/Users/' + uid + '/rightswipe/rightswipelist').once('value').then((snapshot)=>{
      // if the value is 0 [meaning it's a new user], insert first {date: {videoid: watchtime}} element into the list
        // console.log('user does not exist');
      if(snapshot.val()===0){
        let myObj={};
        let innerObj={};
        innerObj[videoID]= time;
        if(time){
          myObj[today]=innerObj;
        } else {
          myObj[today]=0;
        }
        userref.child(uid).child('rightswipe').update({'rightswipelist': myObj});
      } else {
      // if this is not a new user
        // console.log('user exists');
        // check if the current date exists in the list
        if(snapshot.val().hasOwnProperty(today)){
          // if the current date exists
            // console.log('current date exists');

            firebase.database().ref('/Users/' + uid + '/rightswipe/rightswipelist/'+today+'/').once('value').then((snapshot2)=>{
            // check if the current videoid exists
              if(snapshot2.val().hasOwnProperty(videoID)){
                // current videoid exists ==> update the current video value
                // console.log('current videoid exists');

                firebase.database().ref('/Users/'+uid+'/rightswipe/rightswipelist/'+today+'/'+videoID+'/').once('value').then((snapshot3)=>{
                  let myObj3={};
                  myObj3[videoID]= parseFloat(snapshot3.val())+time;
                  userref.child(uid).child('rightswipe').child('rightswipelist').child(today).update(myObj3);
                })
              } else {
                // current videoid does not exist ==> create new entry
                // console.log('current videoid does not exist');
                let myObj2 ={};
                if(time){
                  myObj2[videoID]=time;
                } else {
                  myObj2[videoID]=0;
                }
                userref.child(uid).child('rightswipe').child('rightswipelist').child(today).update(myObj2);
              }
            }).catch((error) => console.error(error));
        } else {
          // if the current date doesn't exist, create currentdate and initialize it with {videoid: watchtime}
          // console.log('current date doesnt exist')
          let myObj4={};
          let myObj5={};
          if(time){
            myObj5[videoID]=time;
          } else {
            myObj5[videoID]=0;
          }
          myObj4[today]=myObj5;
          userref.child(uid).child('rightswipe').child('rightswipelist').update(myObj4);
        }
      }
    }).catch((error) => {console.error(error)});



    /////////////////////////////
    // Update videoswatched
    //// increment total
    firebase.database().ref('/Users/' + uid +'/videoswatched/total').once('value').then((snapshot)=> {
      userref.child(uid).child('videoswatched').update({'total': snapshot.val() + 1});
    }).catch((error) => {console.error(error)});

    //// add current videoid to videolist. Each element in list is {videoid: timespent}
    firebase.database().ref('/Users/' + uid +'/videoswatched/videolist').once('value').then((snapshot)=> {
      // if the value is 0 [meaning it's a new user], insert first {videoid: timespent in seconds} element into the list
      if(snapshot.val()===0){
        let myObj={};
        if(time){
          myObj[videoID]=time;
        }
        else {
          myObj[videoID]=0;
        }
        userref.child(uid).child('videoswatched').update({'videolist': myObj});
      } else {
        // if this is not a new user
        // check if the current videoID exists in the list
        if(snapshot.val().hasOwnProperty(videoID)){
          // if the current videoID exists, update the value
          firebase.database().ref('/Users/'+uid+'/videoswatched/videolist/'+videoID+'/').once('value').then((ss)=>{
            let myObj2={};
            myObj2[videoID]=parseFloat(ss.val())+time;
            userref.child(uid).child('videoswatched').child('videolist').update(myObj2);
          })
        } else {
          // if the current videoID doesn't exist, create videoID and initialize it with timespent
          let myObj3={};
          if(time){
            myObj3[videoID]=time;
          } else {
            myObj3[videoID]=0;
          }
          userref.child(uid).child('videoswatched').child('videolist').update(myObj3);
        }
      }
    }).catch((error) => {console.error(error)});

    /////////////////////////////
    // Update timespent
    //// increment total in hr:min:s
    firebase.database().ref('/Users/' + uid +'/timespent/total').once('value').then((snapshot)=> {

      //TODO: check this function (buggy)
      console.log(snapshot.val());

      userref.child(uid).child('timespent').update({'total': time+parseFloat(snapshot.val())});
    }).catch((error) => {console.error(error)});

    //// add time spent on video to total time per day to list [in {date: timespent in seconds}]
    firebase.database().ref('/Users/' + uid + '/timespent/timeperday').once('value').then((snapshot)=>{
      // if the value is 0 [meaning it's a new user], insert first {date: timespent in seconds} element into the list
      if(snapshot.val()===0){
        let myObj={};
        if(time){
          myObj[today]=time;
        } else {
          myObj[today]=0;
        }
        userref.child(uid).child('timespent').update({'timeperday': myObj});
      } else {
      // if this is not a new user
        // check if the current date exists in the list
        if(snapshot.val().hasOwnProperty(today)){
          // if the current date exists, update the value
          firebase.database().ref('/Users/'+uid+'/timespent/timeperday/'+today+'/').once('value').then((ss)=>{
            let myObj2={};
            myObj2[today]=parseFloat(ss.val())+time;
            userref.child(uid).child('timespent').child('timeperday').update(myObj2);
          })
        } else {
          // if the current date doesn't exist, create currentdate and initialize it with timespent
          let myObj3={};
          if(time){
            myObj3[today]=time;
          } else {
            myObj3[today]=0;
          }
          userref.child(uid).child('timespent').child('timeperday').update(myObj3);
        }
      }
    }).catch((error) => {console.error(error)});


    ///////////////////////////////////////////////////////////////////////////////////////
    // Select the next video to watch [NOTE: these variables don't update instantly! cf. React hooks under the hood: https://medium.com/the-guild/under-the-hood-of-reacts-hooks-system-eb59638c9dba]

    setCount(counter+1);
    setVideoURL(listofvideos[counter+1]);
    setVideoID(listofIDs[counter+1]);
    // Player.src = listofvideos[counter]
    // setPosterURI("https://media.giphy.com/media/5GoVLqeAOo6PK/giphy.gif")
  };

  // const swipeupaction = () => {
  //   console.log("Swiped Up. Switch Topic.")
  //   setCount(counter+1)
  //   setVideoURL(props.listofvideos[counter])
  //   console.log(videourl)
  //   // Player.src = listofvideos[counter]
  //   // setPosterURI("https://media.giphy.com/media/3o7aCXpnE2y0YCCoJa/giphy.gif")
  //   console.log("before writing into firebase db")
  //   let a = firebase.database().ref('Users');
  //   a.update({"email":`monkeybrain up`});
  //   console.log("after writing into firebase db")
  // };

  return(
    <div>
        <MyVideoPlayer
          url={videourl}
          light={posterURI}
          width={window.innerWidth}
          onLeft={swipeleftaction}
          onRight={swiperightaction}
        >
        </MyVideoPlayer>
        <div style={{
              display: 'flex',
              flex: 9,
              justifyContent: "center",
              alignItems: "center",
              alignContent: "stretch",
              backgroundColor: 'black',
              width: '100%',
              height: '100px'
        }}>
        </div>
        <div style={{
                backgroundColor: 'black',
                flex: 1,
                width: 'screen.width',
                height: '100%'
                // height: '100px'
        }}>
          <Row>
            <Col xs></Col>
            <Col xs>
              <Row>
              <img src="/nope.png" height="36" width="36" className="centerimage" onClick={swipeleftaction} alt="white unlit lightbulb"></img>
              {/* <img src="/switch2.png" height="36" width="36" className="centerimage" onClick={swipeupaction}></img> */}
              <img src="/yay.png" height="36" width="36" className="centerimage" onClick={swiperightaction} alt="yellow lit lightbulb"></img>
              </Row>
            </Col>
            <Col xs></Col>
          </Row>
          <Row>
            {/* <Col xs >
              <img src="/nope.png" height="40" width="40" className="centerimage" onClick={swipeleftaction}></img>
              <p style={{ color: '#F2F2F2', textAlign: 'center'}}>Swipe left to dismiss video</p>
            </Col>
            <Col xs >
              <img src="/switch2.png" height="40" width="40" className="centerimage" onClick={swipeupaction}></img>
              <p style={{ color: '#F2F2F2', textAlign: 'center'}}>Swipe up to change the topic</p>
            </Col>
            <Col xs >
              <img src="/yay.png" height="40" width="40" className="centerimage" onClick={swiperightaction}></img>
              <p style={{ color: '#F2F2F2', textAlign: 'center'}}>Swipe right to like video</p>
            </Col> */}
            {/* <Col xs >
              <button><img src="/nope.png" height="50" width="50" className="centerimage" onClick={swipeleftaction}></img></button>
              <p style={{ color: '#F2F2F2', textAlign: 'center'}}>Swipe left to dismiss video</p>
            </Col>
            <Col xs >
              <button><img src="/switch.png" height="50" width="50" className="centerimage" onClick={swipeupaction}></img></button>
              <p style={{ color: '#F2F2F2', textAlign: 'center'}}>Swipe up to change the topic</p>
            </Col>
            <Col xs >
              <button><img src="/yay.png" height="50" width="50" className="centerimage" onClick={swiperightaction}></img></button>
              <p style={{ color: '#F2F2F2', textAlign: 'center'}}>Swipe right to like video</p>
            </Col> */}
          </Row>
            {/* <Row>
              <Col xs >Topic</Col>
              <Col xs >Swipe</Col>
              <Col xs >History</Col>
              <Col xs >Settings</Col>
            </Row>     */}
        </div>
        <div style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: 'black',
                width: '100%',
                height: '32px'
        }}>
        </div>
        <ShareButtonCollection videourl={videourl} videoid={videoID}></ShareButtonCollection>
    </div>
  );
};

class Enlightened extends Component {

  constructor(props){
    super(props);

    // Use API to get list of videoURLs and videoIDs of today or buffer it

    this.listofvideos = [



      "http://um-bubble.media.mit.edu:80/static/1614171724353.e16bd882-8b58-41b8-956b-3e5a175678f4.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614171726116.0820d1e6-80dd-4325-bb9c-7b55dff996d2.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614171920732.1d0d499b-ba86-47ea-ba68-ae2b73e109d8.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614175871010.c558dd0a-10f6-4e53-acf0-4ce0f5c68d91.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176429320.6165eb3f-ecb8-40c1-b789-637fe6795063.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176439155.357e1d6a-c930-494c-8764-fefa34af6d5a.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176450049.3abe569c-729f-4a05-b1b7-8fd7263cd479.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176456154.9e4029ec-c584-474e-9495-ceed53e5b8ae.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176463976.448671fd-72d5-4a1b-8d5a-d7b9b59233f5.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176471529.07d58c59-56ac-420d-af9e-bd79419c4915.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176476630.6220228d-1d3c-4532-9af6-2bc71bee7595.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176795587.2fc738e2-643a-41e2-b40e-687b771eefa9.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614176814405.83595d7f-ed25-48fe-9363-00402349e08a.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614177007706.1dfc3052-7ba0-4ed9-aaad-42681e96e2ca.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614177031684.a5197679-6956-4dda-b80d-342b47633579.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614177040420.4aca2f03-08a3-45d5-9775-2b4163e31f17.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614177050892.828e7359-42e2-4031-9eb2-c7cbf0603007.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614177058819.50767bde-c145-4886-8979-849b1c412e93.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614177066655.778507b4-5071-4465-8016-b5b4a968c1c0.mp4",
      "http://um-bubble.media.mit.edu:80/static/1614177073036.a82e01f9-1183-48e6-8c01-ce634b4c5994.mp4",
      "https://s3.amazonaws.com/super-glue-do-not-delete/video/5c93118c895bbb0007d6a8bd0-60.mp4",
      "https://s3.amazonaws.com/super-glue-do-not-delete/video/5c93118c895bbb0007d6a8bd60-120.mp4",
      "https://s3.amazonaws.com/super-glue-do-not-delete/video/5c93118c895bbb0007d6a8bd120-180.mp4",
      "https://s3.amazonaws.com/super-glue-do-not-delete/video/5c93118c895bbb0007d6a8bd180-240.mp4",
      "https://s3.amazonaws.com/super-glue-do-not-delete/video/5c93118c895bbb0007d6a8bd240-300.mp4",
      "https://s3.amazonaws.com/super-glue-do-not-delete/video/5c93118c895bbb0007d6a8bd300-360.mp4",
      "https://s3.amazonaws.com/super-glue-do-not-delete/video/5c93118c895bbb0007d6a8bd360-420.mp4"
    ]

    this.listofIDs = [
      "60364e4d9f9f4cd4cb8a69f5",
      "60364e4e9f9f4cd4cb8a69f6",
      "60364f119f9f4cd4cb8a69f7",
      "60365e7f9f9f4cd4cb8a69f8",
      "603660ae9f9f4cd4cb8a69f9",
      "603660b89f9f4cd4cb8a69fa",
      "603660c39f9f4cd4cb8a69fb",
      "603660c89f9f4cd4cb8a69fc",
      "603660d89f9f4cd4cb8a69fe",
      "603660dd9f9f4cd4cb8a69ff",
      "6036621c9f9f4cd4cb8a6a00",
      "6036622f9f9f4cd4cb8a6a01",
      "603662f09f9f4cd4cb8a6a02",
      "603663089f9f4cd4cb8a6a03",
      "603663119f9f4cd4cb8a6a04",
      "6036631c9f9f4cd4cb8a6a05",
      "603663239f9f4cd4cb8a6a06",
      "6036632b9f9f4cd4cb8a6a07",
      "603663319f9f4cd4cb8a6a08",
      "5cabbb0eaa1ff84f1ce5ab5d",
      "5cabbb0eaa1ff84f1ce5ab5e",
      "5cabbb0faa1ff84f1ce5ab5f",
      "5cabbb0faa1ff84f1ce5ab60",
      "5cabbb0faa1ff84f1ce5ab61",
      "5cabbb0faa1ff84f1ce5ab62",
      "5cabbb0faa1ff84f1ce5ab63",
      "603660d09f9f4cd4cb8a69fd",
    ]

    // trying to migrate stateless components into this class
    this.counter = 0;
    this.videourl = this.listofvideos[this.counter]; // TODO: make videourl a global state (use Redux)
    let ush = (100-100*((132+window.outerWidth*9/16)/(window.outerHeight)))/2;
    this.uppersectionheight = ush + 'vh';
  }

  render() {
    return (
    <div>
      <div style={{
              display: 'flex',
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: 'black',
              width: '100%',
              height: this.uppersectionheight
      }}>
      </div>
      <VideoSwipe listofvideos={this.listofvideos} listofIDs={this.listofIDs}></VideoSwipe>
    </div>
    );
  }
}

export default Enlightened;

// const NavBar = () => (
//   <div className="navbar">
//     <h3>Task Manager</h3>
//     <Link to="/">Current Tasks</Link>
//     <Link to="/completed">Completed Tasks</Link>
//   </div>
// );

// const Template = (props) => (
//   <div>
//     <NavBar />
//     <p className="page-info">
//       {props.title}:
//     </p>
//     <ul className={props.status}>
//         <li>Task 1</li>
//         <li>Task 2</li>
//         <li>Task 3</li>
//     </ul>

//   </div>
// );

// const handlers = useSwipeable({ onSwiped: (eventData) => eventHandler, ...config })
// return (<div {...handlers}> You can swipe here </div>)