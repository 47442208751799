// I need to write a custom class based on https://video-react.js.org/components/player/#getState - because otherwise I cannot access the current play time

import React, { useRef } from 'react';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useSwipeable } from 'react-swipeable';

const MyVideoPlayer = ({ url, light, width, onLeft, onRight }) => { //This is how you destructure props
    
    const player = useRef();

    // React Hooks
    const [globaltime, setGlobal] = useState(0);
    const [clipduration, setDuration] = useState(0);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            let time = player.current.getCurrentTime();
            onLeft(time + globaltime.time);
        },
        onSwipedRight: () => {
            let time = player.current.getCurrentTime();
            onRight(time + globaltime.time);
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
      });

    const setTimeToMax = () => {
        setGlobal(clipduration);
    }

    const handleDuration = (duration) => {
        console.log('onDuration', duration)
        // this.setState({ duration })
        setDuration(duration)
    }

    return (
        <div {...handlers}>
                <ReactPlayer
                    ref={player}
                    url={url}
                    playsinline={true}
                    playing={true}
                    light={light}
                    width={width}
                    controls={true}
                    loop={false}
                    onEnded={setTimeToMax}
                    onDuration={handleDuration}
                />
        </div>
    );
}

export default MyVideoPlayer;