import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';

import BTInfo from './BTInfo';
import BTList from './BTList';

import axios from 'axios';
import firebase from 'firebase/app';

import giphy from '../assets/giphy.gif';

const config = {
    myurl: process.env.REACT_APP_RECSYS_API_URL
};

class BiasTracker extends Component {
  
    constructor(props) {

        super(props);
        this.state = {
            isLoaded: false,
            smartobject: null,
            dummyobject: 
            {
                avg: {
                    conservative: "50",
                    liberal: "50"
                },
                timeline: 0
                // [
                    // {
                    //     date: "2 April",
                    //     conservative: "24",
                    //     liberal: "76"
                    // },
                    // {
                    //     date: "31 March",
                    //     conservative: "42",
                    //     liberal: "58"
                    // },
                    // {
                    //     date: "21 March",
                    //     conservative: "90",
                    //     liberal: "10"
                    // }
                // ]
            }
        }

        this._isMounted = false;
        this.axiosCancelSource = null;
        this.thisUser = null;
    }

    // https://apexcharts.com/docs/update-charts-from-json-api-ajax/


    async componentDidMount() {
        this._isMounted = true;
        await firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              // User is signed in
               let uid = firebase.auth().currentUser.uid;
               if(this._isMounted){
                this.thisUser = uid;
               }
            } else {
              // No user is signed in.
              console.error('user is not signed in. something is weird.');
              return null;
            }
        }); // with ES6 there is no need for .bind(this) while using arrow functions
        console.log(this.thisUser)
        await new Promise(resolve => {setTimeout(resolve, 500);});
        console.log(this.thisUser)

        this.axiosCancelSource = axios.CancelToken.source()

        const apiUrl = config["myurl"]+('api/biastracker');
        console.log(apiUrl);
        axios.get(apiUrl, {
            params: {
                userid: this.thisUser
            }
        })
        .then(
            (res) => {
                const mydata = res.data['biastracker']
                this._isMounted && this.setState({
                    smartobject: mydata,
                    isLoaded: true
                });
                // console.log(mydata)

                // Optional: write stats into firebase or cache it for Offline First Mode
            },
            (error) => {
                this._isMounted && this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    componentWillUnmount(){
        if(this.axiosCancelSource){
            this.axiosCancelSource.cancel('Axios request canceled.')
        }
        this._isMounted = false;
    }

    render() {
        return (
            <div>
                {/* Info */}
                <div style={{
                    display: 'flex',
                    alignItems: "center",
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginTop: '2vh',
                    backgroundColor: 'black',
                    height: '13vh',
                    flex: 1,
                    flexDirection: 'column'
                }}>
                    <BTInfo></BTInfo>
                </div>
                
                {/* List */}
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "top-center",
                    backgroundColor: 'black',
                    height: '63vh',
                    flex: 1,
                    flexDirection: 'column',
                    marginTop: '2vh'
                }}>
                    {this.state.isLoaded ? <BTList dummyobject={this.state.smartobject}></BTList> :   
                    <Row style={{justifyContent: "center", alignItems: "center" }}>
                        <img src={giphy} alt="Loading" style={{height: "10vh"}}/>
                    </Row>}
                </div>
            </div>
        );
      }
}

export default BiasTracker;