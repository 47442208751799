import React, { Component } from 'react';
import giphy from '../assets/giphy.gif';
import { Row, Col } from 'react-flexbox-grid';

class VideoLoading extends Component {

    render(){
        return(      
            <Col>
                <Row style={{justifyContent: "center", alignItems: "center" }}>
                    <img src={giphy} alt="Loading" style={{height: "30vh"}}/>
                </Row>
                <Row style={{justifyContent: "center", alignItems: "center" }}>
                    <p style={{color: 'white', justifyContent: "center", alignItems: "center", textAlign: "center", height: "3vh", fontWeight: "bold", marginTop: "5vh"}}> Summarizing TV News From The Last 48 Hours - For You :) </p>
                </Row>
            </Col>
        )
    }
}

export default VideoLoading;