import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useSwipeable } from 'react-swipeable';

const MyVideoPlayerUpDown = ({ url, width, onUp, onDown, player, finishedPlaying}) => { //This is how you destructure props
    
    // const player = useRef();

    // React Hooks
    const [globaltime, setGlobal] = useState(0);
    const [clipduration, setDuration] = useState(0);
    const handlers = useSwipeable({
        onSwipedUp: () => {
            let time = player.current.getCurrentTime();
            // console.log('logging time')
            // console.log(time + globaltime)
            if (globaltime > 0) {
                onUp(globaltime);
                setGlobal(0);
            } else {
                onUp(time);
                setGlobal(0);
            }
        },
        onSwipedDown: () => {
            let time = player.current.getCurrentTime();
            // console.log('logging time');
            // console.log(time + globaltime)
            if (globaltime > 0) {
                onDown(globaltime);
                setGlobal(0);
            } else {
                onDown(time);
                setGlobal(0);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const setTimeToMax = () => {
        setGlobal(clipduration);
        finishedPlaying(true);
        // console.log('setTimeToMax', clipduration);
        player.current.seekTo(0);
    }

    const handleDuration = (duration) => {
        if (duration === null)
        {

        } else {
            // console.log('onDuration', duration);
            setDuration(duration);
        }
    }
    
    return (
        <div {...handlers}>
                <ReactPlayer
                    ref={player}
                    url={url}
                    playsinline={true}
                    playing={true}
                    width={width}
                    height='80vh'
                    controls={true}
                    loop={false}
                    onEnded={setTimeToMax}
                    onDuration={handleDuration}
                />
        </div>
    );

}

export default MyVideoPlayerUpDown;