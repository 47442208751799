import React, { Component } from 'react';

import {
  EmailShareButton,
  FacebookShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  // WorkplaceShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  // InstapaperIcon,
  RedditIcon,
  TelegramIcon,
  // FacebookMessengerShareButton,
  // FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon
} from "react-share";

import firebase from 'firebase/app';

class ShareButtonCollection extends Component {
  constructor(props){
    super(props);
    this.emailfirebase = this.emailfirebase.bind(this);
    this.facebookfirebase = this.facebookfirebase.bind(this);
  }

  //ToDo: figure out how to do this

  commonfirebaseupdate = async () => {

    // Update Sharedvideos Total
    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;

    await firebase.database().ref('/Users/' + uid +'/sharedvideos/total').once('value').then((snapshot)=> {
      userref.child(uid).child('sharedvideos').update({'total': snapshot.val() + 1});
    })

    // Update Sharedvideos List - Each element in list is {videoid}
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/sharedvideoslist').once('value').then((snapshot)=> {
      console.log("passing videoid");
      console.log(this.videoid);
      console.log("passing videoid");
      
      // If list is empty
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').update({'sharedvideoslist': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();
        console.log('current value');
        console.log(snapshot.val());

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }

        console.log('updated videoid');
        console.log(this.props.videoid);

        console.log('updated array');
        console.log(myArray);

        userref.child(uid).child('sharedvideos').update({'sharedvideoslist': myArray});
      }

    }).catch((error)=>console.error(error));
  }

  emailfirebase = async () => {
    console.log("hello email frens");
    this.commonfirebaseupdate();

    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;
    // Update ByPlatform
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/email/total').once('value').then((snapshot) => {
      userref.child(uid).child('sharedvideos').child('platform').child('email').update({'total': snapshot.val() + 1});
    })

    // sharedvideos/platform/email/list
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/email/list').once('value').then((snapshot) => {
      // If list is empty
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').child('platform').child('email').update({'list': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }
        userref.child(uid).child('sharedvideos').child('platform').child('email').update({'list': myArray});
      }
    })

  }

  facebookfirebase = async () => {
    console.log("hello facebook frens");
    this.commonfirebaseupdate();

    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;
    // Update ByPlatform
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/facebook/total').once('value').then((snapshot) => {
      userref.child(uid).child('sharedvideos').child('platform').child('facebook').update({'total': snapshot.val() + 1});
    })

    // sharedvideos/platform/facebook/list
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/facebook/list').once('value').then((snapshot) => {
      // If list is empty
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').child('platform').child('facebook').update({'list': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }
        userref.child(uid).child('sharedvideos').child('platform').child('facebook').update({'list': myArray});
      }
    })
  }

  twitterfirebase = async () => {
    console.log("hello twitter frens");
    this.commonfirebaseupdate();

    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;
    // Update ByPlatform
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/twitter/total').once('value').then((snapshot) => {
      userref.child(uid).child('sharedvideos').child('platform').child('twitter').update({'total': snapshot.val() + 1});
    })

    // sharedvideos/platform/twitter/list
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/twitter/list').once('value').then((snapshot) => {
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').child('platform').child('twitter').update({'list': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }
        userref.child(uid).child('sharedvideos').child('platform').child('twitter').update({'list': myArray});
      }
    })
  }

  whatsappfirebase = async () => {
    console.log("hello whatsapp frens");
    this.commonfirebaseupdate();

    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;
    // Update ByPlatform
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/whatsapp/total').once('value').then((snapshot) => {
      userref.child(uid).child('sharedvideos').child('platform').child('whatsapp').update({'total': snapshot.val() + 1});
    })

    // sharedvideos/platform/whatsapp/list
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/whatsapp/list').once('value').then((snapshot) => {
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').child('platform').child('whatsapp').update({'list': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }
        userref.child(uid).child('sharedvideos').child('platform').child('whatsapp').update({'list': myArray});
      }
    })
  }

  telegramfirebase = async () => {
    console.log("hello telegram frens");
    this.commonfirebaseupdate();

    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;
    // Update ByPlatform
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/telegram/total').once('value').then((snapshot) => {
      userref.child(uid).child('sharedvideos').child('platform').child('telegram').update({'total': snapshot.val() + 1});
    })

    // sharedvideos/platform/telegram/list
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/telegram/list').once('value').then((snapshot) => {
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').child('platform').child('telegram').update({'list': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }
        userref.child(uid).child('sharedvideos').child('platform').child('telegram').update({'list': myArray});
      }
    })
  }

  redditfirebase = async () => {
    console.log("hello reddit frens");
    this.commonfirebaseupdate();

    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;
    // Update ByPlatform
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/reddit/total').once('value').then((snapshot) => {
      userref.child(uid).child('sharedvideos').child('platform').child('reddit').update({'total': snapshot.val() + 1});
    })

    // sharedvideos/platform/reddit/list
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/reddit/list').once('value').then((snapshot) => {
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').child('platform').child('reddit').update({'list': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }
        userref.child(uid).child('sharedvideos').child('platform').child('reddit').update({'list': myArray});
      }
    })
  }

  linkedinfirebase = async () => {
    console.log("hello linkedin frens");
    this.commonfirebaseupdate();

    let userref = firebase.database().ref('Users');
    let uid = firebase.auth().currentUser.uid;
    // Update ByPlatform
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/linkedin/total').once('value').then((snapshot) => {
      userref.child(uid).child('sharedvideos').child('platform').child('linkedin').update({'total': snapshot.val() + 1});
    })

    // sharedvideos/platform/linkedin/list
    await firebase.database().ref('/Users/' + uid +'/sharedvideos/platform/linkedin/list').once('value').then((snapshot) => {
      if(snapshot.val()===0){
        userref.child(uid).child('sharedvideos').child('platform').child('linkedin').update({'list': [this.props.videoid]});
      } else {
        // add videoid to existing list, but make sure there are no duplicates
        let myArray = snapshot.val();

        if(myArray.includes(this.props.videoid)){
          // don't push if it already exists
        } else {
          myArray.push(this.props.videoid);
        }
        userref.child(uid).child('sharedvideos').child('platform').child('linkedin').update({'list': myArray});
      }
    })
    
  }

  render(){
    return(
      <div style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: 'black',
                    width: '100%',
                    height: '15vh',
                    // marginBottom: '5vh'
      }}>
                <EmailShareButton url={this.props.videourl} beforeOnClick={this.emailfirebase}>
                  <EmailIcon size={'8vh'}></EmailIcon>
                </EmailShareButton>
                <FacebookShareButton url={this.props.videourl} beforeOnClick={this.facebookfirebase}>
                    <FacebookIcon size={'8vh'} ></FacebookIcon>
                </FacebookShareButton>
                {/* <FacebookMessengerShareButton url="www.youtube.com" disabled disabledStyle>
                  <FacebookMessengerIcon url="www.youtube.com" size={32} ></FacebookMessengerIcon>
                </FacebookMessengerShareButton> */}
                <TwitterShareButton url={this.props.videourl} beforeOnClick={this.twitterfirebase}>
                  <TwitterIcon size={'8vh'} ></TwitterIcon>
                </TwitterShareButton>
                <WhatsappShareButton url={this.props.videourl} beforeOnClick={this.whatsappfirebase} separator=":: ">
                  <WhatsappIcon size={'8vh'}></WhatsappIcon>
                </WhatsappShareButton>
                <TelegramShareButton url={this.props.videourl} beforeOnClick={this.telegramfirebase}>
                  <TelegramIcon size={'8vh'} ></TelegramIcon>
                </TelegramShareButton>
                <RedditShareButton url={this.props.videourl} beforeOnClick={this.redditfirebase}>
                  <RedditIcon size={'8vh'}></RedditIcon>
                </RedditShareButton>
                <LinkedinShareButton url={this.props.videourl} beforeOnClick={this.linkedinfirebase}>
                  <LinkedinIcon size={'8vh'}></LinkedinIcon>
                </LinkedinShareButton>
      </div>
    );
  }
}

export default ShareButtonCollection;