import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

import '../App.css';

import welcome2 from '../assets/welcome2.JPG';

import { useSwipeable } from 'react-swipeable';

const Welcome2Child = ({redirectnow}) => {

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            console.log("swiped left in welcome 2")
            redirectnow(true);
        },
        onSwipedRight: () => {
            console.log("swiped right in welcome 2")
            redirectnow(true);
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div>
            <div style={{
                display:'flex',
                alignItems: "center",
                justifyContent: "center",
                height: '100vh'
            }} {...handlers}>
                <img src={welcome2} width='100%' alt='welcome page 2'/>
            </div>
        </div>
    );

}


class Welcome2 extends Component {

    constructor(props){
        super(props);
        this.setRedirectValue = this.setRedirectValue.bind(this);
    }

    state = {
        redirect: false
    }
    
    renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/welcome3' />
        }
    }

    setRedirectValue = (value) =>{
        this.setState({
            redirect: value
        })
    }

    render() {
      return (
        <div>
            {this.renderRedirect()}
            <Welcome2Child redirectnow={this.setRedirectValue}></Welcome2Child>
        </div>
      );
    }
    
}

export default Welcome2;