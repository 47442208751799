import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import './App.css';
import Enlightened from './screens/Enlightened';
import Enlightened_v3 from './screens/Enlightened_v3';
import Nolan from './screens/Nolan';
import Welcome1 from './screens/Welcome1';
import Welcome2 from './screens/Welcome2';
import Welcome3 from './screens/Welcome3';
import Welcome4 from './screens/Welcome4';
import Welcome5 from './screens/Welcome5';
import Profile from './screens/Profile';

import 'bootstrap/dist/css/bootstrap.min.css';


class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div>
          <Route exact path="/" component={Welcome1}/>
          <Route exact path="/welcome2" component={Welcome2}/>
          <Route exact path="/welcome3" component={Welcome3}/>
          <Route exact path="/welcome4" component={Welcome4}/>
          <Route exact path="/welcome5" component={Welcome5}/>
          <Route exact path="/nolan" component={Nolan}/>
          <Route exact path="/main" component={Enlightened_v3}/>
          <Route exact path="/oldmain" component={Enlightened}/>
          <Route exact path="/profile" component={Profile}/>
        </div>
      </BrowserRouter>
    );
  }

}

export default App;